import gql from 'graphql-tag';

const ADHERENCE_FRAGMENT = gql`
  fragment AdherenceFragment on Adherence {
    id
    timestamp
    status
    SuppRecord {
      id
      time
      nextdate
      createdAt
      quantity
      type
      start_date
      adherences_allowed
      end_date
      count
      Adherences(limit: 1) {
        id
        timestamp
        status
      }
      Product {
        id
        name
        weight
        unit
        additional_info
        Branch {
          id
          name
        }
        ProductKinds {
          id
          name
        }
      }
    }
  }
`;

const BRANCH_FRAGMENT = gql`
  fragment BranchFragment on Branch {
    id
    name
    address
    contact_number
    latitude
    longitude
    email
    terms_and_conditions_url
    privacy_policy_url
    photos
    opening_hours
    colors
    payment
    additional_info
    BranchTargeting {
      id
      visibility
      status
      pet_type
    }
  }
`;

const VACC_RECORD_FRAGMENT = gql`
  fragment VaccRecordFragment on VaccRecord {
    id
    name
    dates
    nextdate
    createdAt
    updatedAt
    color
    batch_number
    end_date
    VaccRecordType {
      id
      name
    }
    MedConds(limit: 1) {
      id
      name
      Branch {
        id
        name
      }
    }
  }
`;

const SUPP_RECORD_FRAGMENT = gql`
  ${ADHERENCE_FRAGMENT}
  fragment SuppRecordFragment on SuppRecord {
    id
    time
    createdAt
    quantity
    type
    start_date
    nextdate
    adherences_allowed
    end_date
    count
    Adherences(limit: 1) {
      ...AdherenceFragment
    }
    MedConds(limit: 1) {
      id
      name
      Branch {
        id
        name
      }
    }
    Product {
      id
      name
      weight
      unit
      additional_info
      Branch {
        id
        name
      }
      ProductKinds {
        id
        name
      }
    }
  }
`;

const PETRECORD_FRAGMENT = gql`
  ${VACC_RECORD_FRAGMENT}
  ${SUPP_RECORD_FRAGMENT}
  fragment PetRecordFragment on PetRecord {
    id
    shape
    vet_name
    BranchId
    measurements
    microchip_number
    microchip_provider
    passport_number
    allergies
    neutred
    disabilities
    chronic_conditions
    MedConds {
      id
      name
    }
    VaccRecords {
      ...VaccRecordFragment
    }
    SuppRecords {
      ...SuppRecordFragment
    }
    BranchPetRecordConnections {
      id
      BranchId
      status
    }
    Meals {
      id
      time
      quantity
      unit
      name
      BranchCategoryId
    }
    BranchPetRecordTags(order: "name") {
      id
      name
      color
    }
    BranchPetRecordItems(where: { type: "DAYCARE" }) {
      id
      days
      type
      Branch {
        id
        name
      }
    }
    Pet {
      id
      name
      profile_pic
      PetRecord {
        id
        BranchPetRecordTags(order: "name") {
          id
          name
        }
      }
      Breed {
        id
        name
      }
    }
    Branch {
      id
      name
      address
      BranchCategories {
        id
        name
        description
      }
    }
    BranchCategories {
      id
      name
      description
    }
    vet
    insurer
  }
`;

const NOTE_FRAGMENT = gql`
  fragment NoteFragment on Note {
    id
    timestamp
    body
    BusUser {
      id
      name
    }
    Branch {
      id
      name
    }
    Appointment {
      id
      PetRecord {
        id
        Pet {
          id
        }
      }
      OrderItem {
        id
        Product {
          id
          name
        }
      }
    }
    visibility
  }
`;

const MED_CONDS_FRAGMENT = gql`
  fragment MedCondFragment on MedCond {
    id
    name
    dates
    description
    createdAt
    updatedAt
    PetRecordId
    PetRecord {
      id
      shape
      measurements
    }
    Branch {
      id
      name
    }
  }
`;

const PET_FRAGMENT = gql`
  ${PETRECORD_FRAGMENT}
  fragment PetFragment on Pet {
    id
    gender
    type
    name
    profile_pic
    birthdate
    createdAt
    updatedAt
    Breed {
      id
      name
    }
    PetRecord {
      ...PetRecordFragment
    }
    PetColor {
      id
      name
    }
    AppUser {
      id
      name
      profile_pic
      email
      addresses
      postcode
    }
  }
`;

const PET_BEHAVIOR_FRAGMENT = gql`
  fragment PetBehaviorFragment on PetBehavior {
    id
    behavior_around_same_type
    behavior_around_other_pets
    behavior_around_children
    behavior_around_adults
    behavior_negative
    behavior_positive
    sleep_place
    favorite_treats
    favorite_activities
    feeding_routine
    walking_places
    daily_routine
    left_alone_duration
    treatment_routine
  }
`;

const GROOMING_REPORT_FRAGMENT = gql`
  fragment GroomingReportFragment on GroomingReport {
    id
    name
    dates
    description
    createdAt
    updatedAt
    PetRecordId
    PetRecord {
      id
      shape
      measurements
    }
    Branch {
      id
      name
    }
    Appointment {
      id
      timestamp
      timestamp_until
      OrderItem {
        item
      }
    }
  }
`;

const TRAINING_REPORT_FRAGMENT = gql`
  fragment TrainingReportFragment on TrainingReport {
    id
    name
    dates
    description
    createdAt
    updatedAt
    PetRecordId
    PetRecord {
      id
      shape
      measurements
    }
    Branch {
      id
      name
    }
    Appointment {
      id
      timestamp
      timestamp_until
      OrderItem {
        item
      }
    }
  }
`;

const WALKING_REPORT_FRAGMENT = gql`
  fragment WalkingReportFragment on WalkingReport {
    id
    name
    dates
    description
    createdAt
    updatedAt
    PetRecordId
    PetRecord {
      id
      shape
      measurements
    }
    Branch {
      id
      name
    }
    Appointment {
      id
      timestamp
      timestamp_until
      OrderItem {
        item
      }
    }
  }
`;

const SITTING_REPORT_FRAGMENT = gql`
  fragment SittingReportFragment on SittingReport {
    id
    name
    dates
    description
    createdAt
    updatedAt
    PetRecordId
    PetRecord {
      id
      shape
      measurements
    }
    Branch {
      id
      name
    }
    Appointment {
      id
      timestamp
      timestamp_until
      OrderItem {
        item
      }
    }
  }
`;

const DAYCARE_REPORT_FRAGMENT = gql`
  fragment DaycareReportFragment on DaycareReport {
    id
    name
    dates
    description
    createdAt
    updatedAt
    PetRecordId
    PetRecord {
      id
      shape
      measurements
    }
    Branch {
      id
      name
    }
    Appointment {
      id
      timestamp
      timestamp_until
      OrderItem {
        item
      }
    }
  }
`;

const BRANCH_APPOINTMENT_TAG_FRAGMENT = gql`
  fragment BranchAppointmentTagFragment on BranchAppointmentTag {
    id
    name
    automations
    quick_action
    appointmentsCount
    appointmentsCountToday
    productsAppointmentsCount {
      Product {
        id
        name
      }
      count
    }
    color
    capacity
    Branch {
      id
      name
    }
    reset_at
    visibility
    BranchTagCategories(limit: 1) {
      id
      name
    }
  }
`;

const BRANCH_COUNTS_FRAGMENT = gql`
  fragment BranchCountsFragment on BranchCounts {
    totalProductOrdersCount
    totalProductOrdersAmount
    totalAppointmentsCount
    totalAppointmentsOrdersCount
    totalAppointmentsAmount
    totalSubscriptionOrdersCount
    totalSubscriptionOrdersAmount
    totalOrdersCount
    totalOrdersAmount
    totalProductOrdersAppUsersCount
    totalAppointmentsAppUsersCount
    totalSubscriptionOrdersAppUsersCount
    totalBilledSubscriptionOrderRepeatsAmount
    totalRefundedSubscriptionOrderRepeatsAmount
    totalEstimatedOrdersAmount
    totalEstimatedSubscriptionOrderRepeatsAmount
    totalActiveSubscriptionOrderRepeatsAmount
    totalPendingSubscriptionOrderRepeatsAmount
    totalRejectedSubscriptionOrderRepeatsAmount
    totalUnbilledOrderSubscriptionRepeatsAmount
    allTotalUnbilledOrderSubscriptionRepeatsAmount
    allTotalUnbilledOrderSubscriptionRepeatsCount
    totalUnbilledOrderSubscriptionRepeatsCount
    totalOrderSubscriptionRepeatsAmount
    totalBilledOrderSubscriptionRepeatsAmount
    totalPendingOrderSubscriptionRepeatsAmount
    totalRejectedOrderSubscriptionRepeatsAmount
    totalActiveSubscriptionOrdersAmount
    totalActiveSubscriptionOrdersCount
    totalSubscriptionOrderRepeatsAmount
    totalAppUsersCount
    totalOrderInvoicesCount
    totalOrderInvoicesAmount
    totalBilledOrderInvoicesAmount
    totalPendingOrderInvoicesAmount
    totalRejectedOrderInvoicesAmount
    totalUnbilledOrderInvoicesAmount
    totalPaymentAppointmentsCount
    totalPaymentAppointmentsAmount
    totalBilledAppointmentsAmount
    totalPendingAppointmentsAmount
    totalRejectedAppointmentsAmount
    totalUpcomingAppointmentsAmount
    totalBilledAppointmentsCount
    totalPendingAppointmentsCount
    totalRejectedAppointmentsCount
    totalUpcomingAppointmentsCount
  }
`;

const ORDER_SUBSCRIPTION_COUNTS_FRAGMENT = gql`
  fragment OrderSubscriptionCountsFragment on OrderSubscriptionCounts {
    totalOrderSubscriptionsCount
    totalOrderSubscriptionsAmount
    activeOrderSubscriptionsCount
    activeOrderSubscriptionsAmount
    canceledOrderSubscriptionsCount
    canceledOrderSubscriptionsAmount
    pausedOrderSubscriptionsCount
    pausedOrderSubscriptionsAmount
    onTrialSubscriptionsCount
    onTrialSubscriptionsAmount
  }
`;

const BOOKINGS_APPOINTMENTS = gql`
  ${BRANCH_APPOINTMENT_TAG_FRAGMENT}
  fragment AppointmentFragment on Appointment {
    id
    timestamp
    timestamp_until
    status
    BranchAppointmentTags {
      ...BranchAppointmentTagFragment
    }
    PetRecord {
      id
      Pet {
        id
        name
        profile_pic
        birthdate
        PetRecord {
          id
          BranchPetRecordTags(order: "name") {
            id
            name
          }
          BranchPetRecordConnections {
            id
            BranchId
            status
          }
        }
        Breed {
          id
          name
        }
      }
    }
    OrderItem {
      id
      item
      quantity
      customizations
      Order {
        id
        number
        payment
        total
        credit_used
        status
        billed_on
        credit_used
        SubmittedForms {
          id
        }
        OrderBills(limit: 1) {
          id
          status
          billed_on
          OrderInvoice {
            id
            Order {
              id
              status
            }
          }
        }
        AppUser {
          id
          name
          profile_pic
          email
          addresses
          postcode
        }
        Branch {
          id
          booking_slots
          max_booking_slots
          address
        }
        Refunds {
          id
          amount
          reason
          note
        }
      }
    }
    BusUsers {
      id
      name
      profile_pic
    }
    BranchRooms {
      id
      name
      availability
    }
  }
`;

const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    status
    actions
    number
    payment
    total
    credit_used
    delivery
    createdAt
    billed_on
    AppUser {
      id
      name
      profile_pic
      email
      postcode
    }
    Products {
      id
    }
    Refunds {
      id
      amount
      reason
      note
    }
  }
`;

const REFUND_FRAGMENT = gql`
  fragment RefundFragment on Refund {
    id
    createdAt
    reason
    amount
    note
    reverse_transfer
    reverse_app_fee
    status
    payment
    OrderId
  }
`;

const ORDER_SUBSCRIPTION_REPEAT_FRAGMENT = gql`
  fragment OrderSubscriptionRepeatFragment on OrderSubscriptionRepeat {
    id
    createdAt
    status
    total
    payment
    PetRecord {
      id
      Pet {
        id
        name
        profile_pic
        Breed {
          id
          name
        }
      }
    }
    Refunds {
      id
      amount
      reason
      note
    }
    OrderItem {
      id
      item
      customizations
      quantity
      customizations
      Order {
        id
        number
        payment
        total
        credit_used
        status
        createdAt
        AppUser {
          id
          name
          profile_pic
          email
          postcode
          addresses
        }
      }
    }
  }
`;

const ORDER_SUBSCRIPTION_FRAGMENT = gql`
  ${ORDER_SUBSCRIPTION_REPEAT_FRAGMENT}
  fragment OrderSubscriptionFragment on OrderSubscription {
    id
    createdAt
    last_order_date
    is_read_by_branch
    period
    status
    trial_end
    ended_at
    start_date
    next_date
    collection_status
    OrderSubscriptionRepeats(order: "createdAt") {
      ...OrderSubscriptionRepeatFragment
    }
  }
`;

const MESSAGE_FRAGMENT = gql`
  fragment MessageFragment on Message {
    id
    body
    ChatRoomId
    notification_sent
    timestamp
    user_id
    user_type
    photos
    location
    video
  }
`;

const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    id
    type
    name
    slots
    payment
    stock
    original_price
    price
    createdAt
    description
    short_description
    photos
    sku
    duration
    sessions
    max_bulk_orders
    cta_text
    customizations
    status
    weight
    unit
    sort_index
    automatically_pick_best_discount
    auto_confirm_order
    auto_confirm_appointments
    use_pets_count
    booking_type
    slots_start_date
    max_slot_offset
    slots_recurrence
    future_delivery
    charge_type
    allows_repeated_orders
    allows_bulk_orders
    user_can_cancel
    additional_info
    user_can_reschedule
    enable_customizations_quantity_factor
    show_customizations_price
    allows_past_slots
    colors
    slugs
    pet_rules
    ProductCategories {
      id
      name
    }
    BranchSchedules {
      id
      name
    }
    PharmaItemProducts {
      id
      price_margin
      PharmaItem {
        id
        description
        cost
        supplier
        pack_size
      }
    }
    Flags {
      id
      name
    }
    SubscriptionProducts {
      id
      name
    }
  }
`;

const BUS_USER_FRAGMENT = gql`
  fragment BusUserFragment on BusUser {
    id
    name
    email
    profile_pic
    position
    role
    status
    contact_number
  }
`;

const BRANCH_ROOM_FRAGMENT = gql`
  fragment BranchRoomFragment on BranchRoom {
    id
    name
    availability
  }
`;

const MESSAGE_READ_STATUS_FRAGMENT = gql`
  fragment MessageReadStatusFragment on MessageReadStatus {
    id
    status
    last_read_message
    last_read_timestamp
    Branch {
      id
    }
    AppUser {
      id
    }
    BusUser {
      id
    }
  }
`;

const BRANCH_APP_USER_TAG_FRAGMENT = gql`
  fragment BranchAppUserTagFragment on BranchAppUserTag {
    id
    name
    automations
    quick_action
    appUsersCount
    color
    capacity
    reset_at
    visibility
    Branch {
      id
      name
    }
    BranchTagCategories(limit: 1) {
      id
      name
    }
  }
`;

const CHATROOM_FRAGMENT = gql`
  fragment ChatRoomFragment on ChatRoom {
    id
    name
    createdAt
    cover_photo
    AppUsers {
      id
      name
      profile_pic
      email
      BranchAppUserTags {
        id
        name
        color
        capacity
      }
    }
    BusUsers {
      id
      name
      profile_pic
      email
    }
    last_message
    read_status
    is_bus_room
  }
`;

const BRANCH_PET_RECORD_TAG_FRAGMENT = gql`
  fragment BranchPetRecordTagFragment on BranchPetRecordTag {
    id
    name
    automations
    quick_action
    petRecordsCount
    color
    capacity
    reset_at
    visibility
    Branch {
      id
      name
    }
    BranchTagCategories(limit: 1) {
      id
      name
    }
  }
`;

const BRANCH_FORM_FRAGMENT = gql`
  fragment BranchFormFragment on Form {
    id
    name
    type
    level
    Orders {
      id
      Answers {
        id
        body
        PetRecordId
        AnswerOptions {
          id
          title
        }
      }
    }
    Questions(order: "sort_index") {
      id
      title
      sort_index
      QuestionCategories {
        id
      }
    }
    updatedAt
    createdAt
    Products {
      id
      name
    }
    Branch {
      id
      name
    }
  }
`;

const SUBMITTED_FORM_FRAGMENT = gql`
  ${BRANCH_FORM_FRAGMENT}
  fragment SubmittedFormFragment on SubmittedForm {
    id
    Order {
      id
      number
    }
    createdAt
    Form {
      ...BranchFormFragment
    }
    AppUser {
      id
      name
      profile_pic
    }
    Answers {
      id
      PetRecordId
      PetRecord {
        id
        Pet {
          id
          name
          Breed {
            id
            name
          }
        }
      }
      Question {
        id
        title
        sort_index
        get_answers_from
        type
        ask_per_pet
      }
      body
      AnswerOptions {
        id
        title
      }
    }
  }
`;

const BRANCH_ORDER_INVOICE_FRAGMENT = gql`
  fragment BranchOrderInvoiceFragment on OrderInvoice {
    id
    status
    amount
    createdAt
    invoice_date
    number
    Order {
      id
      status
      Refunds {
        id
        amount
        reason
        note
      }
    }
    OrderBills(limit: 1) {
      id
      AppUser {
        id
        name
        profile_pic
        payment
      }
    }
  }
`;

const APP_USER_FRAGMENT = gql`
  fragment AppUserFragment on AppUser {
    id
    email
    name
    profile_pic
    addresses
    postcode
    is_verified
    phone_numbers
    payment
    Pets(order: "name") {
      id
      name
      profile_pic
      birthdate
      type
      Breed {
        id
        name
      }
      PetRecord {
        id
        BranchPetRecordConnections {
          id
          BranchId
          status
        }
      }
    }
    ChappedPets(order: "name") {
      id
      name
      profile_pic
      birthdate
      Breed {
        id
        name
      }
      PetRecord {
        id
        BranchPetRecordConnections {
          id
          BranchId
          status
        }
      }
      AppUser {
        id
        profile_pic
      }
    }
    AppUserCredit {
      id
      amount
    }
    BranchAppUserTags {
      id
      name
      color
      capacity
    }
  }
`;

const BRANCH_PAYOUT_FRAGMENT = gql`
  fragment BranchPayoutFragment on BranchPayout {
    id
    amount
    arrival_date
    currency
    description
    status
    type
  }
`;

const BRANCH_BALANCE_TRANSACTION_FRAGMENT = gql`
  fragment BranchBalanceTransactionFragment on BranchBalanceTransaction {
    id
    amount
    created
    currency
    description
    fee
    net
    type
  }
`;

const BRANCH_SCHEDULE_FRAGMENT = gql`
  fragment BranchScheduleFragment on BranchSchedule {
    id
    name
    slots
    booking_type
    slots_recurrence
    slots_start_date
  }
`;

const VOUCHER_FRAGMENT = gql`
  fragment VoucherFragment on Voucher {
    id
    code
    limit
    user_limit
    period
    auto_apply
    status
    createdAt
    ordersCount
    Products {
      id
      name
    }
    AppUsers {
      id
      name
    }
  }
`;

const DISCOUNT_FRAGMENT = gql`
  ${VOUCHER_FRAGMENT}
  fragment DiscountFragment on Discount {
    id
    name
    rules
    amount
    type
    status
    BranchId
    Vouchers {
      ...VoucherFragment
    }
  }
`;

export const EditBranch = gql`
  mutation branchEdit(
    $id: String!
    $delivery_rules: [SequelizeJSON]
    $booking_slots: [SequelizeJSON]
    $max_booking_slots: SequelizeJSON
    $opening_hours: SequelizeJSON
    $additional_info: SequelizeJSON
    $payment: SequelizeJSON
  ) {
    branchEdit(
      Branch: {
        id: $id
        delivery_rules: $delivery_rules
        booking_slots: $booking_slots
        max_booking_slots: $max_booking_slots
        opening_hours: $opening_hours
        additional_info: $additional_info
        payment: $payment
      }
    ) {
      id
      name
      email
      delivery_rules
      booking_slots
      max_booking_slots
      opening_hours
      additional_info
      payment
    }
  }
`;

const APPOINTMENT_CALENDAR_EVENT_FRAGMENT = gql`
  fragment CalendarEventFragment on GetBranchCalendarAppointmentsViewsOutputCalendarView {
    appointmentsIds
    itemName
    orderTotal
    petsNames
    BusUsers {
      id
      name
      profile_pic
    }
    BranchRooms {
      id
      name
      availability
    }
    timestamp
    duration
    branchAppointmentTagsCount {
      BranchAppointmentTag {
        id
        name
      }
      count
    }
    totalNumberOfEvents
    timestamp_until
    itemIndex
    isGroup
    petsCount
    color
    BranchRooms {
      id
      name
    }
  }
`;

const BLOCKED_SLOT_CALENDAR_EVENT_FRAGMENT = gql`
  fragment BlockedSlotCalendarEventFragment on GetBranchCalendarAppointmentsViewsOutputCalendarBlockedView {
    branchSlotsIds
    timestamp
    duration
    period
    productsNames
    isCalendarStyle
    totalNumberOfEvents
    itemIndex
    description
    count
    Products {
      id
      name
    }
    color
  }
`;

const BRANCH_ROUTE_FRAGMENT = gql`
  fragment BranchRouteFragment on BranchRoute {
    id
    name
    start_time
    job_duration
    sort_index
    color
    BranchAreaId
    BranchRoutePetRecords {
      id
      sort_index
      PetRecord {
        id
        Pet {
          id
          name
          profile_pic
          AppUser {
            id
            name
            addresses
          }
        }
      }
      RouteJobTypeId
      RouteJobType {
        id
        name
      }
    }
    BranchRouteProducts {
      id
      Product {
        id
        name
      }
    }
  }
`;

const BRANCH_VEHICLE_FRAGMENT = gql`
  fragment BranchVehicleFragment on BranchVehicle {
    id
    name
    type
    status
    plate_number
    color
    image
    daily_capacity
    BranchVehicleRoutes {
      id
      BranchRoute {
        id
        name
      }
      BusUsers {
        id
        name
      }
    }
  }
`;

export const GetFromFlag = gql`
  query flagGet {
    flagGet(where: { name: "from_label" }) {
      id
      name
    }
  }
`;

export const AddFlagItem = gql`
  mutation flagItemAdd($id: String!, $flagId: String!) {
    flagItemAdd(FlagItem: { ProductId: $id, FlagId: $flagId }) {
      id
    }
  }
`;
export const DeleteFlagItem = gql`
  mutation deleteFlagItem($id: String!, $flagId: String!) {
    deleteFlagItem(DeleteFlagItemInput: { ProductId: $id, FlagId: $flagId }) {
      message
    }
  }
`;

export const GetProductCategories = gql`
  query productCategoryGet {
    productCategoryGet {
      id
      name
    }
  }
`;

export const GetServices = gql`
  ${PRODUCT_FRAGMENT}
  query getProducts($offset: Int) {
    getProducts(offset: $offset, limit: 20, where: { type: ["service"] }) {
      ...ProductFragment
    }
  }
`;

export const GetSubscriptions = gql`
  ${PRODUCT_FRAGMENT}
  query getProducts($offset: Int) {
    getProducts(offset: $offset, limit: 20, where: { type: ["subscription"] }) {
      ...ProductFragment
    }
  }
`;

export const GetAllServices = gql`
  query getProducts {
    getProducts(limit: 1000, where: { type: ["service"] }, order: "name") {
      id
      name
      stock
      status
      booking_type
    }
  }
`;

export const GetBranchProducts = gql`
  ${PRODUCT_FRAGMENT}
  query getBranchProducts(
    $name: [String]
    $product_search: [String]
    $id: [String]
    $type: [String]
    $status: [String]
    $requisite_queries: [String]!
    $alternative_queries: [String]!
    $limit: Int
    $offset: Int
  ) {
    getBranchProducts(
      GetBranchProductsInput: { name: $name, product_search: $product_search, id: $id, type: $type, status: $status, requisite_queries: $requisite_queries, alternative_queries: $alternative_queries }
      limit: $limit
      offset: $offset
    ) {
      ...ProductFragment
    }
  }
`;

export const GetSlotServices = gql`
  query getProducts {
    getProducts(limit: 1000, where: { type: ["service"], booking_type: ["SLOT", "MULTI_SLOT", "MULTI_DAY"] }, order: "name") {
      id
      name
      stock
      status
      booking_type
    }
  }
`;

export const GetAllSubscriptions = gql`
  query getProducts {
    getProducts(limit: 1000, where: { type: ["subscription"] }, order: "name") {
      id
      name
      customizations
    }
  }
`;

export const GetAllSubscriptionsAndServices = gql`
  query getProducts {
    getProducts(limit: 1000, where: { type: ["subscription", "service"] }, order: "name") {
      id
      name
      type
      stock
      status
      booking_type
    }
  }
`;

export const GetAllProductsAllTypes = gql`
  query getProducts {
    getProducts(limit: 1000, where: { type: ["subscription", "service"] }, order: "name") {
      id
      name
      type
      stock
      status
      booking_type
    }
  }
`;

export const GetAllProductTypeProducts = gql`
  query getProducts {
    getProducts(limit: 1000, where: { type: ["product"] }, order: "name") {
      id
      name
      type
      stock
      status
      booking_type
    }
  }
`;

export const GetProduct = gql`
  ${PRODUCT_FRAGMENT}
  query productGet($id: String!) {
    productGet(id: $id) {
      ...ProductFragment
    }
  }
`;

export const AddBranchProduct = gql`
  ${PRODUCT_FRAGMENT}
  mutation addBranchProduct(
    $name: String
    $price: String
    $stock: String
    $description: String
    $short_description: String
    $original_price: String
    $cta_text: String
    $duration: String
    $sessions: Int
    $sku: String
    $booking_type: String
    $photos: [String]
    $customizations: SequelizeJSON
    $status: String
    $weight: String
    $unit: String
    $future_delivery: Boolean
    $automatically_pick_best_discount: Boolean
    $auto_confirm_order: Boolean
    $auto_confirm_appointments: Boolean
    $use_pets_count: Boolean
    $slots_start_date: Date
    $slots_recurrence: Int
    $type: String
    $slots: [SequelizeJSON]
    $charge_type: String
    $allows_repeated_orders: Boolean
    $allows_bulk_orders: Boolean
    $allows_past_slots: Boolean
    $sort_index: Int
    $user_can_cancel: Boolean
    $additional_info: SequelizeJSON
    $user_can_reschedule: Boolean
    $enable_customizations_quantity_factor: Boolean
    $show_customizations_price: Boolean
    $max_bulk_orders: Int
    $max_slot_offset: Int
    $payment: SequelizeJSON
    $branch_schedules: [String]
    $product_categories: [String]
    $flags: [String]
    $colors: SequelizeJSON
    $slugs: SequelizeJSON
    $pet_rules: SequelizeJSON
    $product_subscription_dependencies: [String]
  ) {
    addBranchProduct(
      AddBranchProductInput: {
        name: $name
        price: $price
        stock: $stock
        description: $description
        short_description: $short_description
        original_price: $original_price
        cta_text: $cta_text
        duration: $duration
        sessions: $sessions
        sku: $sku
        booking_type: $booking_type
        photos: $photos
        customizations: $customizations
        status: $status
        weight: $weight
        unit: $unit
        future_delivery: $future_delivery
        type: $type
        automatically_pick_best_discount: $automatically_pick_best_discount
        auto_confirm_order: $auto_confirm_order
        auto_confirm_appointments: $auto_confirm_appointments
        use_pets_count: $use_pets_count
        slots_start_date: $slots_start_date
        slots_recurrence: $slots_recurrence
        slots: $slots
        charge_type: $charge_type
        allows_repeated_orders: $allows_repeated_orders
        allows_bulk_orders: $allows_bulk_orders
        allows_past_slots: $allows_past_slots
        sort_index: $sort_index
        user_can_cancel: $user_can_cancel
        additional_info: $additional_info
        user_can_reschedule: $user_can_reschedule
        enable_customizations_quantity_factor: $enable_customizations_quantity_factor
        show_customizations_price: $show_customizations_price
        max_bulk_orders: $max_bulk_orders
        max_slot_offset: $max_slot_offset
        payment: $payment
        branch_schedules: $branch_schedules
        product_categories: $product_categories
        flags: $flags
        colors: $colors
        slugs: $slugs
        pet_rules: $pet_rules
        product_subscription_dependencies: $product_subscription_dependencies
      }
    ) {
      ...ProductFragment
    }
  }
`;
export const EditBranchProduct = gql`
  ${PRODUCT_FRAGMENT}
  mutation editBranchProduct(
    $id: String!
    $name: String
    $price: String
    $stock: String
    $description: String
    $short_description: String
    $original_price: String
    $cta_text: String
    $duration: String
    $sessions: Int
    $sku: String
    $booking_type: String
    $photos: [String]
    $customizations: SequelizeJSON
    $status: String
    $weight: String
    $unit: String
    $future_delivery: Boolean
    $automatically_pick_best_discount: Boolean
    $auto_confirm_order: Boolean
    $auto_confirm_appointments: Boolean
    $use_pets_count: Boolean
    $slots_start_date: Date
    $slots_recurrence: Int
    $type: String
    $slots: [SequelizeJSON]
    $charge_type: String
    $allows_repeated_orders: Boolean
    $allows_bulk_orders: Boolean
    $allows_past_slots: Boolean
    $sort_index: Int
    $user_can_cancel: Boolean
    $additional_info: SequelizeJSON
    $user_can_reschedule: Boolean
    $enable_customizations_quantity_factor: Boolean
    $show_customizations_price: Boolean
    $max_bulk_orders: Int
    $max_slot_offset: Int
    $payment: SequelizeJSON
    $branch_schedules: [String]
    $product_categories: [String]
    $flags: [String]
    $colors: SequelizeJSON
    $slugs: SequelizeJSON
    $pet_rules: SequelizeJSON
    $product_subscription_dependencies: [String]
  ) {
    editBranchProduct(
      EditBranchProductInput: {
        id: $id
        name: $name
        price: $price
        stock: $stock
        description: $description
        short_description: $short_description
        original_price: $original_price
        cta_text: $cta_text
        duration: $duration
        sessions: $sessions
        sku: $sku
        booking_type: $booking_type
        photos: $photos
        customizations: $customizations
        status: $status
        weight: $weight
        unit: $unit
        future_delivery: $future_delivery
        type: $type
        automatically_pick_best_discount: $automatically_pick_best_discount
        auto_confirm_order: $auto_confirm_order
        auto_confirm_appointments: $auto_confirm_appointments
        use_pets_count: $use_pets_count
        slots_start_date: $slots_start_date
        slots_recurrence: $slots_recurrence
        slots: $slots
        charge_type: $charge_type
        allows_repeated_orders: $allows_repeated_orders
        allows_bulk_orders: $allows_bulk_orders
        allows_past_slots: $allows_past_slots
        sort_index: $sort_index
        user_can_cancel: $user_can_cancel
        additional_info: $additional_info
        user_can_reschedule: $user_can_reschedule
        enable_customizations_quantity_factor: $enable_customizations_quantity_factor
        show_customizations_price: $show_customizations_price
        max_bulk_orders: $max_bulk_orders
        max_slot_offset: $max_slot_offset
        payment: $payment
        branch_schedules: $branch_schedules
        product_categories: $product_categories
        flags: $flags
        colors: $colors
        slugs: $slugs
        pet_rules: $pet_rules
        product_subscription_dependencies: $product_subscription_dependencies
      }
    ) {
      ...ProductFragment
    }
  }
`;

export const DeleteProduct = gql`
  mutation deleteProduct($id: String!) {
    deleteProduct(DeleteProductInput: { id: $id }) {
      message
    }
  }
`;
export const AddNewProductCategory = gql`
  mutation productCategoryAdd($name: String!) {
    productCategoryAdd(ProductCategory: { name: $name }) {
      id
    }
  }
`;
export const AttachCategoryToProduct = gql`
  mutation productCategoryItemAdd($ProductId: String!, $ProductCategoryId: String!) {
    productCategoryItemAdd(ProductCategoryItem: { ProductId: $ProductId, ProductCategoryId: $ProductCategoryId }) {
      id
    }
  }
`;
export const DeleteProductCategoryItem = gql`
  mutation deleteProductCategoryItem($ProductId: String!, $ProductCategoryId: String!) {
    deleteProductCategoryItem(DeleteProductCategoryItemInput: { ProductId: $ProductId, ProductCategoryId: $ProductCategoryId }) {
      message
    }
  }
`;
export const GetProducts = gql`
  ${PRODUCT_FRAGMENT}
  query getProducts($offset: Int, $name: [String]) {
    getProducts(offset: $offset, limit: 20, where: { type: "product" }, GetProductsInput: { name: $name }) {
      ...ProductFragment
    }
  }
`;

export const RefreshToken = gql`
  mutation refreshTokenBusUser($refreshToken: String!) {
    refreshTokenBusUser(RefreshTokenInput: { refreshToken: $refreshToken }) {
      token
      refreshToken
    }
  }
`;

export const UpdateOrderSubscription = gql`
  ${ORDER_SUBSCRIPTION_FRAGMENT}
  mutation updateOrderSubscription($id: String!, $ProductId: String, $overriddenTotal: Float, $VoucherId: [String], $customizations: SequelizeJSON, $collectionStatus: String, $trialEnd: String) {
    updateOrderSubscription(
      UpdateOrderSubscriptionInput: {
        ProductId: $ProductId
        id: $id
        overriddenTotal: $overriddenTotal
        VoucherId: $VoucherId
        customizations: $customizations
        collectionStatus: $collectionStatus
        trialEnd: $trialEnd
      }
    ) {
      ...OrderSubscriptionFragment
    }
  }
`;

export const UpdateOrderSubscriptionStatus = gql`
  ${ORDER_SUBSCRIPTION_FRAGMENT}
  mutation updateOrderSubscriptionStatus($id: String!, $status: String!) {
    updateOrderSubscriptionStatus(UpdateOrderSubscriptionStatusInput: { id: $id, status: $status }) {
      ...OrderSubscriptionFragment
    }
  }
`;

export const StartChatRoomWithAppUser = gql`
  ${CHATROOM_FRAGMENT}
  mutation startChatRoomWithAppUser($AppUserId: String!) {
    startChatRoomWithAppUser(StartChatRoomWithAppUserInput: { AppUserId: $AppUserId }) {
      ...ChatRoomFragment
    }
  }
`;
export const AddBusUserToChatRoom = gql`
  mutation busUserChatConnectionAdd($BusUserId: String!, $ChatRoomId: String!) {
    busUserChatConnectionAdd(BusUserChatConnection: { BusUserId: $BusUserId, ChatRoomId: $ChatRoomId }) {
      id
    }
  }
`;

export const GetBranchChatRooms = gql`
  ${CHATROOM_FRAGMENT}
  query getBranchChatRooms(
    $offset: Int
    $limit: Int
    $chatRoom_appUserId: [String]
    $chat_branchAppUserTag_name: [String]
    $chat_search: [String]
    $chat_seen: Boolean
    $requisite_queries: [String]
    $alternative_queries: [String]
    $order_by_is_read: Boolean
    $chatRoom_id: [String]
  ) {
    getBranchChatRooms(
      offset: $offset
      limit: $limit
      GetBranchChatRoomsInput: {
        chatRoom_appUserId: $chatRoom_appUserId
        chat_branchAppUserTag_name: $chat_branchAppUserTag_name
        chat_search: $chat_search
        chat_seen: $chat_seen
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
        order_by_is_read: $order_by_is_read
        chatRoom_id: $chatRoom_id
      }
    ) {
      ...ChatRoomFragment
    }
  }
`;

export const GetBranchChatRoomsViews = gql`
  ${CHATROOM_FRAGMENT}
  query getBranchChatRoomsViews(
    $offset: Int
    $limit: Int
    $chatRoom_appUserId: [String]
    $chat_branchAppUserTag_name: [String]
    $chat_search: [String]
    $chat_seen: Boolean
    $requisite_queries: [String]
    $alternative_queries: [String]
    $order_by_is_read: Boolean
    $chatRoom_id: [String]
    $is_bus_room: Boolean
  ) {
    getBranchChatRoomsViews(
      offset: $offset
      limit: $limit
      GetBranchChatRoomsViewsInput: {
        chatRoom_appUserId: $chatRoom_appUserId
        chat_branchAppUserTag_name: $chat_branchAppUserTag_name
        chat_search: $chat_search
        chat_seen: $chat_seen
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
        order_by_is_read: $order_by_is_read
        chatRoom_id: $chatRoom_id
        is_bus_room: $is_bus_room
      }
    ) {
      views {
        ...ChatRoomFragment
      }
      count
    }
  }
`;

export const GetBranchChatRoom = gql`
  ${CHATROOM_FRAGMENT}
  ${MESSAGE_READ_STATUS_FRAGMENT}
  query getBranchChatRooms($requisite_queries: [String!]!, $chatRoom_id: [String!]!) {
    getBranchChatRooms(GetBranchChatRoomsInput: { requisite_queries: $requisite_queries, chatRoom_id: $chatRoom_id }) {
      ...ChatRoomFragment
      MessageReadStatuses {
        ...MessageReadStatusFragment
      }
    }
  }
`;

export const GetMessages = gql`
  ${MESSAGE_FRAGMENT}
  query getMessages($offset: Int, $ChatRoomId: String!) {
    getMessages(limit: 30, offset: $offset, GetMessagesInput: { ChatRoomId: $ChatRoomId }) {
      ...MessageFragment
    }
  }
`;

export const SendChatMessage = gql`
  ${MESSAGE_FRAGMENT}
  mutation messageAdd(
    $id: String
    $body: String
    $ChatRoomId: String
    $notification_sent: Boolean
    $timestamp: Date
    $user_id: String
    $user_type: String
    $photos: [String]
    $location: SequelizeJSON
    $video: SequelizeJSON
  ) {
    messageAdd(
      Message: {
        id: $id
        body: $body
        ChatRoomId: $ChatRoomId
        notification_sent: $notification_sent
        timestamp: $timestamp
        user_id: $user_id
        user_type: $user_type
        photos: $photos
        location: $location
        video: $video
      }
    ) {
      ...MessageFragment
    }
  }
`;

export const SendChatMessageToAppUsers = gql`
  ${MESSAGE_FRAGMENT}
  mutation messageAdd(
    $id: String
    $body: String
    $ChatRoomId: String
    $notification_sent: Boolean
    $timestamp: Date
    $user_id: String
    $user_type: String
    $photos: [String]
    $location: SequelizeJSON
    $video: SequelizeJSON
  ) {
    messageAdd(
      Message: {
        id: $id
        body: $body
        ChatRoomId: $ChatRoomId
        notification_sent: $notification_sent
        timestamp: $timestamp
        user_id: $user_id
        user_type: $user_type
        photos: $photos
        location: $location
        video: $video
      }
    ) {
      ...MessageFragment
    }
  }
`;

export const UpdateChatRoomMessageReadStatus = gql`
  ${CHATROOM_FRAGMENT}
  mutation updateChatRoomMessageReadStatus($chatRoomId: String!, $status: String!) {
    updateChatRoomMessageReadStatus(UpdateChatRoomMessageReadStatusInput: { chatRoomId: $chatRoomId, status: $status }) {
      ...ChatRoomFragment
      Messages {
        id
        timestamp
        user_id
        body
        photos
        location
        video
      }
    }
  }
`;

export const ChatSubscription = gql`
  subscription messageAdded($BranchId: [String], $ChatRooms: [String]) {
    messageAdded(BranchId: $BranchId, ChatRooms: $ChatRooms) {
      id
      body
      ChatRoomId
      notification_sent
      timestamp
      user_id
      user_type
      photos
      location
      video
      isSilent
    }
  }
`;

export const BranchUnreadItemsSubscription = gql`
  subscription branchUnreadItemsUpdated($BranchId: [String], $BusUserId: [String]) {
    branchUnreadItemsUpdated(BranchId: $BranchId, BusUserId: $BusUserId) {
      unreadServiceOrdersCount
      unreadProductOrdersCount
      unreadSubscriptionOrdersCount
      unreadChatRoomsCount
      shouldNotify
    }
  }
`;

export const BranchServiceOrdersViewCountsSubscription = gql`
  subscription branchServiceOrdersViewCountsUpdated($BranchId: [String]) {
    branchServiceOrdersViewCountsUpdated(BranchId: $BranchId) {
      newServiceOrdersCount
      paymentServiceOrdersCount
      canceledServiceOrdersCount
      shouldNotify
    }
  }
`;

export const BranchAppUsersViewCountsSubscription = gql`
  subscription branchAppUsersViewCountsUpdated($BranchId: [String]) {
    branchAppUsersViewCountsUpdated(BranchId: $BranchId) {
      submittedFormsCount
      appUsersCount
      petsCount
      petsTypesCount {
        DOG_count
        CAT_count
      }
      shouldNotify
    }
  }
`;

export const BranchChatRoomsViewCountsSubscription = gql`
  subscription branchChatRoomsViewCountsUpdated($BranchId: [String]) {
    branchChatRoomsViewCountsUpdated(BranchId: $BranchId) {
      chatRoomsCount
      unreadChatRoomsCount
      shouldNotify
    }
  }
`;

export const BranchProductOrdersViewCountsSubscription = gql`
  subscription branchProductOrdersViewCountsUpdated($BranchId: [String]) {
    branchProductOrdersViewCountsUpdated(BranchId: $BranchId) {
      productOrdersCount
      shouldNotify
    }
  }
`;

export const BranchSubscriptionOrdersViewCountsSubscription = gql`
  subscription branchSubscriptionOrdersViewCountsUpdated($BranchId: [String]) {
    branchSubscriptionOrdersViewCountsUpdated(BranchId: $BranchId) {
      subscriptionOrdersCount
      shouldNotify
    }
  }
`;

export const GetBranchUnreadItemsCount = gql`
  query getBranchUnreadItemsCount {
    getBranchUnreadItemsCount {
      unreadServiceOrdersCount
      unreadProductOrdersCount
      unreadSubscriptionOrdersCount
      unreadChatRoomsCount
      shouldNotify
    }
  }
`;

export const GetBranchServiceOrdersViewCounts = gql`
  query getBranchServiceOrdersViewCounts {
    getBranchServiceOrdersViewCounts {
      newServiceOrdersCount
      paymentServiceOrdersCount
      canceledServiceOrdersCount
      shouldNotify
    }
  }
`;

export const GetBranchAppUsersViewCounts = gql`
  query getBranchAppUsersViewCounts {
    getBranchAppUsersViewCounts {
      submittedFormsCount
      appUsersCount
      petsCount
      petsTypesCount {
        DOG_count
        CAT_count
      }
      shouldNotify
    }
  }
`;

export const GetBranchChatRoomsViewCounts = gql`
  query getBranchChatRoomsViewCounts {
    getBranchChatRoomsViewCounts {
      chatRoomsCount
      unreadChatRoomsCount
      shouldNotify
    }
  }
`;

export const GetBranchProductOrdersViewCounts = gql`
  query getBranchProductOrdersViewCounts {
    getBranchProductOrdersViewCounts {
      productOrdersCount
      shouldNotify
    }
  }
`;

export const GetBranchSubscriptionOrdersViewCounts = gql`
  query getBranchSubscriptionOrdersViewCounts {
    getBranchSubscriptionOrdersViewCounts {
      subscriptionOrdersCount
      shouldNotify
    }
  }
`;

export const LoginBusUser = gql`
  mutation loginBusUser($email: String!, $password: String!) {
    loginBusUser(BusUserLoginInput: { email: $email, password: $password }) {
      token
      refreshToken
      firebaseToken
    }
  }
`;

export const GetBranchPets = gql`
  ${PET_FRAGMENT}
  query getBranchPets(
    $offset: Int
    $branchPetRecordItem_BranchId: [String]
    $branchPetRecordItem_days: [String]
    $branchPetRecordItem_type: [String]
    $pet_BreedId: [String]
    $pet_birthdate_from: String
    $pet_birthdate_to: String
    $pet_birthdate_month_from: Int
    $pet_birthdate_month_to: Int
    $pet_birthdate_day_from: Int
    $pet_birthdate_day_to: Int
    $petRecord_neutred: Boolean
    $vaccRecordType_name: [String]
    $vaccRecord_name: [String]
    $vaccRecord_nextdate_from: String
    $vaccRecord_nextdate_to: String
    $treatment_productKind_name: [String]
    $treatment_product_name: [String]
    $treatment_nextdate_from: String
    $treatment_nextdate_to: String
    $measurements_weight_from: String
    $measurements_weight_to: String
    $pet_id: [String]
    $pet_name: [String]
    $pet_type: [String]
    $branchPetRecordTag_name: [String]
    $branchPetRecordConnection_status: [String]
    $requisite_queries: [String]!
    $alternative_queries: [String]!
  ) {
    getBranchPets(
      limit: 20
      offset: $offset
      GetBranchPetsInput: {
        branchPetRecordItem_BranchId: $branchPetRecordItem_BranchId
        branchPetRecordItem_days: $branchPetRecordItem_days
        branchPetRecordItem_type: $branchPetRecordItem_type
        pet_BreedId: $pet_BreedId
        pet_birthdate_from: $pet_birthdate_from
        pet_birthdate_to: $pet_birthdate_to
        pet_birthdate_month_from: $pet_birthdate_month_from
        pet_birthdate_month_to: $pet_birthdate_month_to
        pet_birthdate_day_from: $pet_birthdate_day_from
        pet_birthdate_day_to: $pet_birthdate_day_to
        petRecord_neutred: $petRecord_neutred
        vaccRecordType_name: $vaccRecordType_name
        vaccRecord_name: $vaccRecord_name
        vaccRecord_nextdate_from: $vaccRecord_nextdate_from
        vaccRecord_nextdate_to: $vaccRecord_nextdate_to
        treatment_productKind_name: $treatment_productKind_name
        treatment_product_name: $treatment_product_name
        treatment_nextdate_from: $treatment_nextdate_from
        treatment_nextdate_to: $treatment_nextdate_to
        measurements_weight_from: $measurements_weight_from
        measurements_weight_to: $measurements_weight_to
        pet_id: $pet_id
        pet_name: $pet_name
        pet_type: $pet_type
        branchPetRecordTag_name: $branchPetRecordTag_name
        branchPetRecordConnection_status: $branchPetRecordConnection_status
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
      }
    ) {
      ...PetFragment
    }
  }
`;

export const GetBranchPetsReduced = gql`
  query getBranchPets(
    $offset: Int
    $branchPetRecordItem_BranchId: [String]
    $branchPetRecordItem_days: [String]
    $branchPetRecordItem_type: [String]
    $pet_BreedId: [String]
    $pet_birthdate_from: String
    $pet_birthdate_to: String
    $pet_birthdate_month_from: Int
    $pet_birthdate_month_to: Int
    $pet_birthdate_day_from: Int
    $pet_birthdate_day_to: Int
    $petRecord_neutred: Boolean
    $vaccRecordType_name: [String]
    $vaccRecord_name: [String]
    $vaccRecord_nextdate_from: String
    $vaccRecord_nextdate_to: String
    $treatment_productKind_name: [String]
    $treatment_product_name: [String]
    $treatment_nextdate_from: String
    $treatment_nextdate_to: String
    $measurements_weight_from: String
    $measurements_weight_to: String
    $pet_id: [String]
    $pet_name: [String]
    $pet_type: [String]
    $branchPetRecordTag_name: [String]
    $requisite_queries: [String]!
    $alternative_queries: [String]!
    $daycare_filter: Boolean!
    $vaccination_filter: Boolean!
    $treatment_filter: Boolean!
    $weight_filter: Boolean!
    $neutering_filter: Boolean!
    $age_filter: Boolean!
    $branchPetRecordConnection_status: [String]
    $petRecord_id: [String]
  ) {
    getBranchPets(
      limit: 20
      offset: $offset
      GetBranchPetsInput: {
        branchPetRecordItem_BranchId: $branchPetRecordItem_BranchId
        branchPetRecordItem_days: $branchPetRecordItem_days
        branchPetRecordItem_type: $branchPetRecordItem_type
        pet_BreedId: $pet_BreedId
        pet_birthdate_from: $pet_birthdate_from
        pet_birthdate_to: $pet_birthdate_to
        pet_birthdate_month_from: $pet_birthdate_month_from
        pet_birthdate_month_to: $pet_birthdate_month_to
        pet_birthdate_day_from: $pet_birthdate_day_from
        pet_birthdate_day_to: $pet_birthdate_day_to
        petRecord_neutred: $petRecord_neutred
        vaccRecordType_name: $vaccRecordType_name
        vaccRecord_name: $vaccRecord_name
        vaccRecord_nextdate_from: $vaccRecord_nextdate_from
        vaccRecord_nextdate_to: $vaccRecord_nextdate_to
        treatment_productKind_name: $treatment_productKind_name
        treatment_product_name: $treatment_product_name
        treatment_nextdate_from: $treatment_nextdate_from
        treatment_nextdate_to: $treatment_nextdate_to
        measurements_weight_from: $measurements_weight_from
        measurements_weight_to: $measurements_weight_to
        pet_id: $pet_id
        pet_name: $pet_name
        pet_type: $pet_type
        branchPetRecordTag_name: $branchPetRecordTag_name
        branchPetRecordConnection_status: $branchPetRecordConnection_status
        petRecord_id: $petRecord_id
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
      }
    ) {
      id
      gender
      type
      name
      profile_pic
      birthdate @include(if: $age_filter)
      Breed {
        id
        name
      }
      PetRecord {
        id
        BranchPetRecordTags(order: "name") {
          id
          name
          color
        }
        BranchPetRecordConnections {
          id
          BranchId
          status
        }
        neutred @include(if: $neutering_filter)
        measurements @include(if: $weight_filter)
        BranchPetRecordItems(where: { type: "DAYCARE" }) @include(if: $daycare_filter) {
          id
          days
          type
          Branch {
            id
            name
          }
        }
        VaccRecords @include(if: $vaccination_filter) {
          id
          nextdate
          VaccRecordType {
            id
            name
          }
        }
        SuppRecords(where: { type: "treatment" }) @include(if: $treatment_filter) {
          id
          nextdate
          Product {
            id
            name
            ProductKinds {
              id
              name
            }
          }
        }
      }
      AppUser {
        id
        name
        postcode
      }
    }
  }
`;

export const GetBranchPetsViews = gql`
  query getBranchPetsViews(
    $offset: Int
    $branchPetRecordItem_BranchId: [String]
    $branchPetRecordItem_days: [String]
    $branchPetRecordItem_type: [String]
    $pet_BreedId: [String]
    $pet_birthdate_from: String
    $pet_birthdate_to: String
    $pet_birthdate_month_from: Int
    $pet_birthdate_month_to: Int
    $pet_birthdate_day_from: Int
    $pet_birthdate_day_to: Int
    $petRecord_neutred: Boolean
    $vaccRecordType_name: [String]
    $vaccRecord_name: [String]
    $vaccRecord_nextdate_from: String
    $vaccRecord_nextdate_to: String
    $treatment_productKind_name: [String]
    $treatment_product_name: [String]
    $treatment_nextdate_from: String
    $treatment_nextdate_to: String
    $measurements_weight_from: String
    $measurements_weight_to: String
    $pet_id: [String]
    $pet_name: [String]
    $pet_type: [String]
    $branchPetRecordTag_name: [String]
    $requisite_queries: [String]!
    $alternative_queries: [String]!
    $daycare_filter: Boolean!
    $vaccination_filter: Boolean!
    $treatment_filter: Boolean!
    $weight_filter: Boolean!
    $neutering_filter: Boolean!
    $age_filter: Boolean!
    $branchPetRecordConnection_status: [String]
    $limit: Int
  ) {
    getBranchPetsViews(
      limit: $limit
      offset: $offset
      GetBranchPetsViewsInput: {
        branchPetRecordItem_BranchId: $branchPetRecordItem_BranchId
        branchPetRecordItem_days: $branchPetRecordItem_days
        branchPetRecordItem_type: $branchPetRecordItem_type
        pet_BreedId: $pet_BreedId
        pet_birthdate_from: $pet_birthdate_from
        pet_birthdate_to: $pet_birthdate_to
        pet_birthdate_month_from: $pet_birthdate_month_from
        pet_birthdate_month_to: $pet_birthdate_month_to
        pet_birthdate_day_from: $pet_birthdate_day_from
        pet_birthdate_day_to: $pet_birthdate_day_to
        petRecord_neutred: $petRecord_neutred
        vaccRecordType_name: $vaccRecordType_name
        vaccRecord_name: $vaccRecord_name
        vaccRecord_nextdate_from: $vaccRecord_nextdate_from
        vaccRecord_nextdate_to: $vaccRecord_nextdate_to
        treatment_productKind_name: $treatment_productKind_name
        treatment_product_name: $treatment_product_name
        treatment_nextdate_from: $treatment_nextdate_from
        treatment_nextdate_to: $treatment_nextdate_to
        measurements_weight_from: $measurements_weight_from
        measurements_weight_to: $measurements_weight_to
        pet_id: $pet_id
        pet_name: $pet_name
        pet_type: $pet_type
        branchPetRecordTag_name: $branchPetRecordTag_name
        branchPetRecordConnection_status: $branchPetRecordConnection_status
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
      }
    ) {
      count
      views {
        id
        gender
        type
        name
        profile_pic
        birthdate @include(if: $age_filter)
        Breed {
          id
          name
        }
        PetRecord {
          id
          BranchPetRecordTags(order: "name") {
            id
            name
            color
          }
          BranchPetRecordConnections {
            id
            BranchId
            status
          }
          neutred @include(if: $neutering_filter)
          measurements @include(if: $weight_filter)
          BranchPetRecordItems(where: { type: "DAYCARE" }) @include(if: $daycare_filter) {
            id
            days
            type
            Branch {
              id
              name
            }
          }
          VaccRecords @include(if: $vaccination_filter) {
            id
            nextdate
            VaccRecordType {
              id
              name
            }
          }
          SuppRecords(where: { type: "treatment" }) @include(if: $treatment_filter) {
            id
            nextdate
            Product {
              id
              name
              ProductKinds {
                id
                name
              }
            }
          }
        }
        AppUser {
          id
          name
        }
      }
    }
  }
`;

export const GetBranchPetProfile = gql`
  ${PET_FRAGMENT}
  query getBranchPetProfile($id: String!) {
    getBranchPetProfile(GetBranchPetProfileInput: { id: $id }) {
      ...PetFragment
    }
  }
`;

export const GetBranchPetVetInsurer = gql`
  query getBranchPetProfile($id: String!) {
    getBranchPetProfile(GetBranchPetProfileInput: { id: $id }) {
      id
      PetRecord {
        id
        vet
        insurer
      }
    }
  }
`;

export const GetReducedBranchPetProfile = gql`
  query getBranchPetProfile($id: String!) {
    getBranchPetProfile(GetBranchPetProfileInput: { id: $id }) {
      id
      name
      profile_pic
      birthdate
      type
    }
  }
`;

export const UpdateOrderStatus = gql`
  ${ORDER_FRAGMENT}
  mutation updateOrderStatus($id: String!, $status: String!, $payment_required: Boolean) {
    updateOrderStatus(UpdateOrderStatusInput: { id: $id, status: $status, payment_required: $payment_required }) {
      ...OrderFragment
    }
  }
`;
export const CompleteOrderPayment = gql`
  ${ORDER_FRAGMENT}
  mutation completeOrderPayment($orderId: String!) {
    completeOrderPayment(CompleteOrderPaymentInput: { orderId: $orderId }) {
      ...OrderFragment
    }
  }
`;
export const RetryOrderPayment = gql`
  ${ORDER_FRAGMENT}
  mutation retryOrderPayment($orderId: String!) {
    retryOrderPayment(RetryOrderPaymentInput: { orderId: $orderId }) {
      ...OrderFragment
    }
  }
`;
export const UpdateOrderAmount = gql`
  ${ORDER_FRAGMENT}
  mutation updateOrderAmount($amount: String!, $orderId: String!) {
    updateOrderAmount(UpdateOrderAmountInput: { amount: $amount, orderId: $orderId }) {
      ...OrderFragment
    }
  }
`;

export const UpdateAppointmentsStatus = gql`
  ${BOOKINGS_APPOINTMENTS}
  mutation updateAppointmentsStatus($id: [String], $status: String!, $OrderId: String, $disableNotifications: Boolean, $refund: UpdateAppointmentsStatusInputRefundInput) {
    updateAppointmentsStatus(UpdateAppointmentsStatusInput: { id: $id, status: $status, OrderId: $OrderId, disableNotifications: $disableNotifications, refund: $refund }) {
      ...AppointmentFragment
    }
  }
`;

export const AddRefund = gql`
  ${REFUND_FRAGMENT}
  mutation addRefund($OrderId: String!, $OrderSubscriptionRepeatId: String, $amount: Float, $reason: String, $note: String) {
    addRefund(AddRefundInput: { OrderId: $OrderId, OrderSubscriptionRepeatId: $OrderSubscriptionRepeatId, amount: $amount, reason: $reason, note: $note }) {
      ...RefundFragment
    }
  }
`;

export const UpdateAppointmentsTimestamp = gql`
  ${BOOKINGS_APPOINTMENTS}
  mutation updateAppointmentsTimestamp($id: [String], $timestamp: String, $timestamp_until: String, $original_timestamp: String, $ProductId: String, $notifications_disabled: Boolean) {
    updateAppointmentsTimestamp(
      UpdateAppointmentsTimestampInput: {
        id: $id
        timestamp: $timestamp
        timestamp_until: $timestamp_until
        original_timestamp: $original_timestamp
        ProductId: $ProductId
        notifications_disabled: $notifications_disabled
      }
    ) {
      ...AppointmentFragment
    }
  }
`;

export const UpdateOrderBillsBilledOnDate = gql`
  mutation updateOrderBillsBilledOnDate(
    $id: [String]
    $billed_on: String
    $original_billed_on: String
    $ProductId: [String]
    $AppUserId: [String]
    $timestamp_from: String
    $timestamp_to: String
    $reason: String
  ) {
    updateOrderBillsBilledOnDate(
      UpdateOrderBillsBilledOnDateInput: {
        id: $id
        billed_on: $billed_on
        original_billed_on: $original_billed_on
        ProductId: $ProductId
        AppUserId: $AppUserId
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        reason: $reason
      }
    ) {
      id
      billed_on
    }
  }
`;

export const GetUpdateOrderBillsBilledOnDateSummary = gql`
  query getUpdateOrderBillsBilledOnDateSummary(
    $id: [String]
    $billed_on: String
    $original_billed_on: String
    $ProductId: [String]
    $AppUserId: [String]
    $timestamp_from: String
    $timestamp_to: String
  ) {
    getUpdateOrderBillsBilledOnDateSummary(
      GetUpdateOrderBillsBilledOnDateSummaryInput: {
        id: $id
        billed_on: $billed_on
        original_billed_on: $original_billed_on
        ProductId: $ProductId
        AppUserId: $AppUserId
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
      }
    ) {
      orderBillsToUpdate
      appUsersToUpdate
    }
  }
`;

export const GetBranchOrderSubscriptionsCounts = gql`
  ${ORDER_SUBSCRIPTION_COUNTS_FRAGMENT}
  query getBranchOrderSubscriptionsCounts(
    $id: [String]
    $status: [String]
    $PetRecordId: [String]
    $OrderSubscriptionRepeat_createdAt_from: String
    $OrderSubscriptionRepeat_createdAt_to: String
    $OrderSubscriptionRepeat_payment_invoice_date_from: String
    $OrderSubscriptionRepeat_payment_invoice_date_to: String
    $OrderSubscriptionRepeat_status: [String]
    $collectionStatus: [String]
    $subscription_search: [String]
    $subscription_product_name: [String]
    $subscription_trial_ended: Boolean
    $subscription_trial_end_from: String
    $subscription_trial_end_to: String
    $subscription_optional_trial_end_to: String
    $requisite_queries: [String]
    $alternative_queries: [String]
  ) {
    getBranchOrderSubscriptionsCounts(
      GetBranchOrderSubscriptionsCountsInput: {
        id: $id
        status: $status
        PetRecordId: $PetRecordId
        OrderSubscriptionRepeat_createdAt_from: $OrderSubscriptionRepeat_createdAt_from
        OrderSubscriptionRepeat_createdAt_to: $OrderSubscriptionRepeat_createdAt_to
        OrderSubscriptionRepeat_payment_invoice_date_from: $OrderSubscriptionRepeat_payment_invoice_date_from
        OrderSubscriptionRepeat_payment_invoice_date_to: $OrderSubscriptionRepeat_payment_invoice_date_to
        OrderSubscriptionRepeat_status: $OrderSubscriptionRepeat_status
        collectionStatus: $collectionStatus
        subscription_search: $subscription_search
        subscription_product_name: $subscription_product_name
        subscription_trial_ended: $subscription_trial_ended
        subscription_trial_end_from: $subscription_trial_end_from
        subscription_trial_end_to: $subscription_trial_end_to
        subscription_optional_trial_end_to: $subscription_optional_trial_end_to
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
      }
    ) {
      counts {
        ...OrderSubscriptionCountsFragment
      }
      subscriptionsProductsCounts {
        Product {
          id
          name
        }
        counts {
          ...OrderSubscriptionCountsFragment
        }
      }
    }
  }
`;

export const AddBranchAppUser = gql`
  ${APP_USER_FRAGMENT}
  mutation addBranchAppUser($email: String, $postcode: String!, $addresses: [SequelizeJSON], $phone_numbers: SequelizeJSON, $name: String!, $country_id: String!) {
    addBranchAppUser(AddBranchAppUserInput: { email: $email, postcode: $postcode, addresses: $addresses, phone_numbers: $phone_numbers, name: $name, country_id: $country_id }) {
      ...AppUserFragment
    }
  }
`;

export const EditBranchSchedule = gql`
  ${BRANCH_SCHEDULE_FRAGMENT}
  mutation editBranchSchedule($id: String!, $booking_type: String!, $slots_start_date: String, $slots_recurrence: Int, $slots: [SequelizeJSON]!, $name: String!) {
    editBranchSchedule(EditBranchScheduleInput: { id: $id, booking_type: $booking_type, slots_start_date: $slots_start_date, slots_recurrence: $slots_recurrence, slots: $slots, name: $name }) {
      ...BranchScheduleFragment
    }
  }
`;

export const EditBranchAppUser = gql`
  ${APP_USER_FRAGMENT}
  mutation editBranchAppUser($id: String!, $postcode: String, $addresses: [SequelizeJSON], $phone_numbers: SequelizeJSON, $name: String, $country_id: String, $email: String) {
    editBranchAppUser(EditBranchAppUserInput: { id: $id, postcode: $postcode, addresses: $addresses, phone_numbers: $phone_numbers, name: $name, country_id: $country_id, email: $email }) {
      ...AppUserFragment
    }
  }
`;

export const AddAppUsersToBranch = gql`
  ${APP_USER_FRAGMENT}
  mutation addAppUsersToBranch($email: [String], $id: [String]) {
    addAppUsersToBranch(AddAppUsersToBranchInput: { email: $email, id: $id }) {
      ...AppUserFragment
    }
  }
`;

export const AddPetToBranch = gql`
  ${PET_FRAGMENT}
  mutation addPetToBranch($id: String!) {
    addPetToBranch(AddPetToBranchInput: { id: $id }) {
      ...PetFragment
    }
  }
`;

export const GetOrderItems = gql`
  ${BOOKINGS_APPOINTMENTS}
  ${ORDER_FRAGMENT}
  query getOrderItems($OrderId: String!) {
    getOrderItems(GetOrderItemsInput: { OrderId: $OrderId }) {
      id
      quantity
      customizations
      item
      Order {
        ...OrderFragment
      }
      Appointments {
        ...AppointmentFragment
      }
    }
  }
`;

export const GetBranchOrderVouchers = gql`
  query getBranchOrderVouchers($OrderId: String!) {
    getBranchOrderVouchers(GetBranchOrderVouchersInput: { OrderId: $OrderId }) {
      id
      voucher
      Voucher {
        id
        Discount {
          id
        }
      }
    }
  }
`;

/**
 * @deprecated use AddBranchDiscount instead
 * @see AddBranchDiscount
 */
export const AddDiscount = gql`
  mutation discountAdd($code: String!, $amount: String!, $percentage: String!, $rules: [SequelizeJSON]!, $automatic_pick_allowed: Boolean) {
    discountAdd(Discount: { code: $code, percentage: $percentage, amount: $amount, rules: $rules, automatic_pick_allowed: $automatic_pick_allowed }) {
      id
      code
      percentage
      amount
      rules
      automatic_pick_allowed
    }
  }
`;
/**
 * @deprecated use EditBranchDiscount instead
 * @see EditBranchDiscount
 */
export const EditDiscount = gql`
  mutation discountEdit($id: String!, $code: String!, $amount: String!, $percentage: String!, $rules: [SequelizeJSON]!, $automatic_pick_allowed: Boolean) {
    discountEdit(Discount: { id: $id, code: $code, percentage: $percentage, amount: $amount, rules: $rules, automatic_pick_allowed: $automatic_pick_allowed }) {
      id
      code
      percentage
      amount
      rules
      automatic_pick_allowed
    }
  }
`;

/**
 * @deprecated use EditBranchDiscount instead
 * @see EditBranchDiscount
 */
export const DeleteDiscount = gql`
  mutation deleteDiscount($id: [String]!) {
    deleteDiscount(DeleteDiscountInput: { id: $id }) {
      message
    }
  }
`;

export const AddBranchDiscount = gql`
  ${DISCOUNT_FRAGMENT}
  mutation addBranchDiscount($name: String!, $rules: SequelizeJSON!, $amount: Float!, $type: String!, $status: String!, $Vouchers: [AddBranchVoucherInput], $extraFields: SequelizeJSON) {
    addBranchDiscount(AddBranchDiscountInput: { name: $name, rules: $rules, amount: $amount, type: $type, status: $status, Vouchers: $Vouchers, extraFields: $extraFields }) {
      ...DiscountFragment
    }
  }
`;

export const EditBranchDiscount = gql`
  ${DISCOUNT_FRAGMENT}
  mutation editBranchDiscount($id: String!, $name: String, $rules: SequelizeJSON, $amount: Float, $type: String, $status: String, $Vouchers: [AddBranchVoucherInput], $extraFields: SequelizeJSON) {
    editBranchDiscount(EditBranchDiscountInput: { id: $id, name: $name, rules: $rules, amount: $amount, type: $type, status: $status, Vouchers: $Vouchers, extraFields: $extraFields }) {
      ...DiscountFragment
    }
  }
`;

export const AddBranchVoucher = gql`
  ${VOUCHER_FRAGMENT}
  mutation AddBranchVoucher(
    $DiscountId: String!
    $code: String!
    $limit: Int
    $user_limit: Int
    $period: SequelizeJSON
    $auto_apply: Boolean!
    $ProductId: [String]
    $AppUserId: [String]
    $status: String!
  ) {
    addBranchVoucher(
      AddBranchVoucherInput: {
        DiscountId: $DiscountId
        code: $code
        limit: $limit
        user_limit: $user_limit
        period: $period
        auto_apply: $auto_apply
        ProductId: $ProductId
        AppUserId: $AppUserId
        status: $status
      }
    ) {
      ...VoucherFragment
    }
  }
`;

export const EditBranchVoucher = gql`
  ${VOUCHER_FRAGMENT}
  mutation editBranchVoucher(
    $id: String!
    $DiscountId: String
    $code: String
    $limit: Int
    $user_limit: Int
    $period: SequelizeJSON
    $auto_apply: Boolean!
    $ProductId: [String]
    $AppUserId: [String]
    $status: String!
  ) {
    editBranchVoucher(
      EditBranchVoucherInput: {
        id: $id
        code: $code
        limit: $limit
        user_limit: $user_limit
        period: $period
        auto_apply: $auto_apply
        ProductId: $ProductId
        AppUserId: $AppUserId
        status: $status
        DiscountId: $DiscountId
      }
    ) {
      ...VoucherFragment
    }
  }
`;

export const DeleteBranchDiscount = gql`
  mutation deleteBranchDiscount($id: [String]!) {
    deleteBranchDiscount(DeleteBranchDiscountInput: { id: $id }) {
      message
    }
  }
`;

export const GetAllBranchDiscounts = gql`
  query getBranchDiscounts($status: [String], $id: [String]) {
    getBranchDiscounts(offset: 0, limit: 1000, GetBranchDiscountsInput: { status: $status, id: $id }) {
      id
      name
      status
    }
  }
`;

export const GetAllBranchDiscountsWithVouchers = gql`
  query getBranchDiscounts($status: [String], $id: [String]) {
    getBranchDiscounts(offset: 0, limit: 1000, GetBranchDiscountsInput: { status: $status, id: $id }) {
      id
      name
      status
      Vouchers {
        id
        code
      }
    }
  }
`;

export const GetBranchDiscounts = gql`
  ${DISCOUNT_FRAGMENT}
  query getBranchDiscounts($status: [String], $id: [String], $offset: Int, $limit: Int) {
    getBranchDiscounts(offset: $offset, limit: $limit, GetBranchDiscountsInput: { status: $status, id: $id }) {
      ...DiscountFragment
    }
  }
`;

export const GetBranchDiscountsWithoutVouchers = gql`
  query getBranchDiscounts($status: [String], $id: [String], $offset: Int, $limit: Int) {
    getBranchDiscounts(offset: $offset, limit: $limit, GetBranchDiscountsInput: { status: $status, id: $id }) {
      id
      name
      rules
      amount
      type
      status
      BranchId
    }
  }
`;

export const GetBranchVouchers = gql`
  ${VOUCHER_FRAGMENT}
  query getBranchVouchers($status: [String], $id: [String], $offset: Int, $limit: Int, $DiscountId: [String], $search: [String]) {
    getBranchVouchers(offset: $offset, limit: $limit, GetBranchVouchersInput: { status: $status, id: $id, DiscountId: $DiscountId, search: $search }) {
      ...VoucherFragment
    }
  }
`;

export const GetReducedBranchDiscounts = gql`
  query getBranchDiscounts($status: [String], $id: [String], $offset: Int, $limit: Int, $search: [String]) {
    getBranchDiscounts(offset: $offset, limit: $limit, GetBranchDiscountsInput: { status: $status, id: $id, search: $search }) {
      id
      name
      amount
      type
      rules
      status
      createdAt
      vouchersCount
    }
  }
`;

export const AddBranchDiscounts = gql`
  mutation addBranchDiscounts($Discounts: [AddBranchDiscountInput]!) {
    addBranchDiscounts(AddBranchDiscountsInput: { Discounts: $Discounts }) {
      id
      name
    }
  }
`;

export const EditBranchDiscounts = gql`
  ${DISCOUNT_FRAGMENT}
  mutation editBranchDiscounts($Discounts: [AddBranchDiscountInput]!) {
    editBranchDiscounts(EditBranchDiscountsInput: { Discounts: $Discounts }) {
      ...DiscountFragment
    }
  }
`;

export const GetBranchOrders = gql`
  ${ORDER_FRAGMENT}
  query getBranchOrders($offset: Int, $limit: Int, $status: [String]!, $type: String!, $createdAt_from: String, $createdAt_to: String, $AppUserId: [String]) {
    getBranchOrders(offset: $offset, limit: $limit, GetBranchOrdersInput: { status: $status, type: $type, createdAt_from: $createdAt_from, createdAt_to: $createdAt_to, AppUserId: $AppUserId }) {
      ...OrderFragment
    }
  }
`;

export const GetBranchOrdersAppointments = gql`
  query getBranchOrders($offset: Int, $limit: Int, $status: [String], $type: String!, $createdAt_from: String, $createdAt_to: String, $AppUserId: [String], $id: [String]) {
    getBranchOrders(
      offset: $offset
      limit: $limit
      GetBranchOrdersInput: { status: $status, type: $type, createdAt_from: $createdAt_from, createdAt_to: $createdAt_to, AppUserId: $AppUserId, id: $id }
    ) {
      id
      OrderItems {
        id
        item
        Appointments {
          id
          timestamp
          timestamp_until
          PetRecord {
            id
            Pet {
              id
              name
              profile_pic
              birthdate
              AppUser {
                id
                name
                profile_pic
              }
            }
          }
        }
      }
    }
  }
`;

export const GetBranchAppointments = gql`
  ${BOOKINGS_APPOINTMENTS}
  query getBranchAppointments(
    $limit: Int
    $offset: Int
    $status: [String]!
    $timestamp_from: String
    $timestamp_to: String
    $billed_on_from: String
    $billed_on_to: String
    $filter_by_role: String
    $PetRecordId: [String]
    $booking_type: [String]!
    $order: String
    $appointment_id: [String]
    $branchAppointmentTag_name: [String]
    $appointment_busUserAssigned_name: [String]
    $appointment_product_name: [String]
    $appointment_timestamp_day_of_week: [Int]
    $appointment_search: [String]
    $appointment_branchPetRecordTag_name: [String]
    $requisite_queries: [String]!
    $alternative_queries: [String]!
    $order_status: [String]
  ) {
    getBranchAppointments(
      limit: $limit
      offset: $offset
      GetBranchAppointmentsInput: {
        status: $status
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        billed_on_from: $billed_on_from
        billed_on_to: $billed_on_to
        filter_by_role: $filter_by_role
        PetRecordId: $PetRecordId
        booking_type: $booking_type
        appointment_id: $appointment_id
        branchAppointmentTag_name: $branchAppointmentTag_name
        appointment_branchPetRecordTag_name: $appointment_branchPetRecordTag_name
        appointment_busUserAssigned_name: $appointment_busUserAssigned_name
        appointment_product_name: $appointment_product_name
        appointment_search: $appointment_search
        appointment_timestamp_day_of_week: $appointment_timestamp_day_of_week
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
        order: $order
        order_status: $order_status
      }
    ) {
      ...AppointmentFragment
    }
  }
`;
export const GetBranchProductsAppointments = gql`
  query getBranchProductsAppointments(
    $ProductId: [String]
    $limit: Int
    $offset: Int
    $status: [String]!
    $timestamp_from: String
    $timestamp_to: String
    $billed_on_from: String
    $billed_on_to: String
    $filter_by_role: String
    $PetRecordId: [String]
    $booking_type: [String]!
    $order: String
    $appointment_id: [String]
    $branchAppointmentTag_name: [String]
    $appointment_busUserAssigned_name: [String]
    $appointment_timestamp_day_of_week: [Int]
    $appointment_search: [String]
    $appointment_branchPetRecordTag_name: [String]
    $requisite_queries: [String]!
    $alternative_queries: [String]!
    $recordTags: Boolean!
    $recordAddress: Boolean!
    $recordBookingNotes: Boolean!
    $recordPetNotes: Boolean!
    $recordFeedingSchedule: Boolean!
    $recordFeedingRoutine: Boolean!
    $recordTreatmentRoutine: Boolean!
    $BranchId: String!
  ) {
    getBranchProductsAppointments(
      limit: $limit
      offset: $offset
      GetBranchProductsAppointmentsInput: {
        ProductId: $ProductId
        status: $status
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        billed_on_from: $billed_on_from
        billed_on_to: $billed_on_to
        filter_by_role: $filter_by_role
        PetRecordId: $PetRecordId
        booking_type: $booking_type
        appointment_id: $appointment_id
        branchAppointmentTag_name: $branchAppointmentTag_name
        appointment_branchPetRecordTag_name: $appointment_branchPetRecordTag_name
        appointment_busUserAssigned_name: $appointment_busUserAssigned_name
        appointment_search: $appointment_search
        appointment_timestamp_day_of_week: $appointment_timestamp_day_of_week
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
        order: $order
      }
    ) {
      productsAppointments {
        count
        Product {
          id
          name
          booking_type
        }
        Appointments {
          id
          timestamp
          timestamp_until
          status
          PetRecord {
            id
            BranchPetRecordTags(where: { visibility: { showOnAppointments: true } }, order: "name") @include(if: $recordTags) {
              id
              name
              color
            }
            Notes(where: { visibility: { BranchId: $BranchId } }) @include(if: $recordPetNotes) {
              id
              body
            }
            Meals @include(if: $recordFeedingSchedule) {
              id
              time
              quantity
              unit
              name
              BranchCategoryId
            }
            BranchCategories @include(if: $recordFeedingRoutine) {
              id
              name
            }
            PetBehavior {
              id
              feeding_routine @include(if: $recordFeedingRoutine)
              treatment_routine @include(if: $recordTreatmentRoutine)
            }
            Pet {
              id
              name
              Breed {
                id
                name
              }
              profile_pic
              AppUser {
                id
                addresses @include(if: $recordAddress)
                postcode @include(if: $recordAddress)
              }
            }
          }
          OrderItem {
            id
            item
            quantity
            Order {
              id
              number
              total
              status
              createdAt
              payment
              AppUser {
                id
                name
              }
              Notes(where: { visibility: { BranchId: $BranchId } }) @include(if: $recordBookingNotes) {
                id
                body
              }
            }
          }
          BranchAppointmentTags {
            id
            name
            color
            capacity
          }
          BusUsers {
            id
            name
            profile_pic
          }
          BranchRooms {
            id
            name
            availability
          }
        }
      }
    }
  }
`;

export const GetBranchBusUsersAppointments = gql`
  query getBranchBusUsersAppointments(
    $BusUserId: [String]
    $limit: Int
    $offset: Int
    $status: [String]!
    $timestamp_from: String
    $timestamp_to: String
    $billed_on_from: String
    $billed_on_to: String
    $filter_by_role: String
    $PetRecordId: [String]
    $booking_type: [String]!
    $order: String
    $appointment_id: [String]
    $branchAppointmentTag_name: [String]
    $appointment_busUserAssigned_name: [String]
    $appointment_timestamp_day_of_week: [Int]
    $appointment_search: [String]
    $appointment_branchPetRecordTag_name: [String]
    $requisite_queries: [String]!
    $alternative_queries: [String]!
    $recordTags: Boolean!
    $recordAddress: Boolean!
    $recordBookingNotes: Boolean!
    $recordPetNotes: Boolean!
    $recordFeedingSchedule: Boolean!
    $recordFeedingRoutine: Boolean!
    $recordTreatmentRoutine: Boolean!
    $BranchId: String!
  ) {
    getBranchBusUsersAppointments(
      limit: $limit
      offset: $offset
      GetBranchBusUsersAppointmentsInput: {
        BusUserId: $BusUserId
        status: $status
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        billed_on_from: $billed_on_from
        billed_on_to: $billed_on_to
        filter_by_role: $filter_by_role
        PetRecordId: $PetRecordId
        booking_type: $booking_type
        appointment_id: $appointment_id
        branchAppointmentTag_name: $branchAppointmentTag_name
        appointment_branchPetRecordTag_name: $appointment_branchPetRecordTag_name
        appointment_busUserAssigned_name: $appointment_busUserAssigned_name
        appointment_search: $appointment_search
        appointment_timestamp_day_of_week: $appointment_timestamp_day_of_week
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
        order: $order
      }
    ) {
      busUsersAppointments {
        count
        BusUser {
          id
          name
          profile_pic
        }
        Appointments {
          id
          timestamp
          timestamp_until
          status
          PetRecord {
            id
            BranchPetRecordTags(where: { visibility: { showOnAppointments: true } }, order: "name") @include(if: $recordTags) {
              id
              name
              color
            }
            Notes(where: { visibility: { BranchId: $BranchId } }) @include(if: $recordPetNotes) {
              id
              body
            }
            Meals @include(if: $recordFeedingSchedule) {
              id
              time
              quantity
              unit
              name
              BranchCategoryId
            }
            BranchCategories @include(if: $recordFeedingRoutine) {
              id
              name
            }
            PetBehavior {
              id
              feeding_routine @include(if: $recordFeedingRoutine)
              treatment_routine @include(if: $recordTreatmentRoutine)
            }
            Pet {
              id
              name
              Breed {
                id
                name
              }
              profile_pic
              AppUser {
                id
                addresses @include(if: $recordAddress)
                postcode @include(if: $recordAddress)
              }
            }
          }
          OrderItem {
            id
            item
            quantity
            Order {
              id
              number
              total
              status
              createdAt
              payment
              AppUser {
                id
                name
              }
              Notes(where: { visibility: { BranchId: $BranchId } }) @include(if: $recordBookingNotes) {
                id
                body
              }
            }
          }
          BranchAppointmentTags {
            id
            name
            color
            capacity
          }
          BusUsers {
            id
            name
            profile_pic
          }
          BranchRooms {
            id
            name
            availability
          }
        }
      }
    }
  }
`;

export const GetBillingListBranchAppointments = gql`
  query getBranchAppointments(
    $limit: Int
    $offset: Int
    $status: [String]!
    $timestamp_from: String
    $timestamp_to: String
    $billed_on_from: String
    $billed_on_to: String
    $filter_by_role: String
    $PetRecordId: [String]
    $booking_type: [String]!
    $order: String
    $appointment_id: [String]
    $branchAppointmentTag_name: [String]
    $appointment_busUserAssigned_name: [String]
    $appointment_product_name: [String]
    $appointment_timestamp_day_of_week: [Int]
    $appointment_search: [String]
    $appointment_branchPetRecordTag_name: [String]
    $requisite_queries: [String]!
    $alternative_queries: [String]!
    $order_status: [String]
  ) {
    getBranchAppointments(
      limit: $limit
      offset: $offset
      GetBranchAppointmentsInput: {
        status: $status
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        billed_on_from: $billed_on_from
        billed_on_to: $billed_on_to
        filter_by_role: $filter_by_role
        PetRecordId: $PetRecordId
        booking_type: $booking_type
        appointment_id: $appointment_id
        branchAppointmentTag_name: $branchAppointmentTag_name
        appointment_branchPetRecordTag_name: $appointment_branchPetRecordTag_name
        appointment_busUserAssigned_name: $appointment_busUserAssigned_name
        appointment_product_name: $appointment_product_name
        appointment_search: $appointment_search
        appointment_timestamp_day_of_week: $appointment_timestamp_day_of_week
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
        order: $order
        order_status: $order_status
      }
    ) {
      id
      timestamp
      timestamp_until
      status
      PetRecord {
        id
        Pet {
          id
          name
          profile_pic
          Breed {
            id
            name
          }
        }
      }
      OrderItem {
        id
        item
        quantity
        Order {
          id
          number
          total
          credit_used
          status
          billed_on
          payment
          AppUser {
            id
            name
          }
          OrderBills(limit: 1) {
            id
            status
            billed_on
            OrderInvoice {
              id
              status
              Order {
                id
                status
              }
            }
          }
        }
      }
      BusUsers {
        id
        name
        profile_pic
      }
      BranchAppointmentTags {
        id
        name
        color
        capacity
      }
    }
  }
`;

export const GetListBranchAppointments = gql`
  query getBranchAppointments(
    $limit: Int
    $offset: Int
    $status: [String]!
    $timestamp_from: String
    $timestamp_to: String
    $billed_on_from: String
    $billed_on_to: String
    $filter_by_role: String
    $PetRecordId: [String]
    $booking_type: [String]
    $order: String
    $order_by_busUserAssigned: Boolean
    $appointment_id: [String]
    $branchAppointmentTag_name: [String]
    $appointment_busUserAssigned_name: [String]
    $appointment_product_name: [String]
    $appointment_timestamp_day_of_week: [Int]
    $appointment_search: [String]
    $appointment_branchPetRecordTag_name: [String]
    $requisite_queries: [String]!
    $alternative_queries: [String]!
    $order_status: [String]
    $recordTags: Boolean!
    $recordAddress: Boolean!
    $recordBookingNotes: Boolean!
    $recordPetNotes: Boolean!
    $recordFeedingSchedule: Boolean!
    $recordFeedingRoutine: Boolean!
    $recordTreatmentRoutine: Boolean!
    $BranchId: String!
  ) {
    getBranchAppointments(
      limit: $limit
      offset: $offset
      GetBranchAppointmentsInput: {
        status: $status
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        billed_on_from: $billed_on_from
        billed_on_to: $billed_on_to
        filter_by_role: $filter_by_role
        PetRecordId: $PetRecordId
        booking_type: $booking_type
        appointment_id: $appointment_id
        branchAppointmentTag_name: $branchAppointmentTag_name
        appointment_branchPetRecordTag_name: $appointment_branchPetRecordTag_name
        appointment_busUserAssigned_name: $appointment_busUserAssigned_name
        appointment_product_name: $appointment_product_name
        appointment_search: $appointment_search
        appointment_timestamp_day_of_week: $appointment_timestamp_day_of_week
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
        order: $order
        order_by_busUserAssigned: $order_by_busUserAssigned
        order_status: $order_status
      }
    ) {
      id
      timestamp
      timestamp_until
      status
      PetRecord {
        id
        BranchPetRecordTags(where: { visibility: { showOnAppointments: true } }, order: "name") @include(if: $recordTags) {
          id
          name
          color
        }
        Notes(where: { visibility: { BranchId: $BranchId } }) @include(if: $recordPetNotes) {
          id
          body
        }
        Meals @include(if: $recordFeedingSchedule) {
          id
          time
          quantity
          unit
          name
          BranchCategoryId
        }
        BranchCategories @include(if: $recordFeedingRoutine) {
          id
          name
        }
        PetBehavior {
          id
          feeding_routine @include(if: $recordFeedingRoutine)
          treatment_routine @include(if: $recordTreatmentRoutine)
        }
        Pet {
          id
          name
          Breed {
            id
            name
          }
          profile_pic
          AppUser {
            id
            addresses @include(if: $recordAddress)
            postcode @include(if: $recordAddress)
          }
        }
      }
      OrderItem {
        id
        item
        quantity
        Order {
          id
          number
          total
          credit_used
          status
          createdAt
          payment
          AppUser {
            id
            name
          }
          Notes(where: { visibility: { BranchId: $BranchId } }) @include(if: $recordBookingNotes) {
            id
            body
          }
        }
      }
      BranchAppointmentTags {
        id
        name
        color
        capacity
      }
      BusUsers {
        id
        name
        profile_pic
      }
      BranchRooms {
        id
        name
        availability
      }
    }
  }
`;

export const GetBranchAppointmentsViews = gql`
  query getBranchAppointmentsViews(
    $limit: Int
    $offset: Int
    $status: [String]!
    $timestamp_from: String
    $timestamp_to: String
    $billed_on_from: String
    $billed_on_to: String
    $filter_by_role: String
    $PetRecordId: [String]
    $booking_type: [String]
    $order: String
    $order_by_busUserAssigned: Boolean
    $appointment_id: [String]
    $branchAppointmentTag_name: [String]
    $appointment_busUserAssigned_name: [String]
    $appointment_product_name: [String]
    $appointment_timestamp_day_of_week: [Int]
    $appointment_search: [String]
    $appointment_branchPetRecordTag_name: [String]
    $requisite_queries: [String]!
    $alternative_queries: [String]!
    $order_status: [String]
    $recordTags: Boolean!
    $recordAddress: Boolean!
    $recordBookingNotes: Boolean!
    $recordPetNotes: Boolean!
    $recordFeedingSchedule: Boolean!
    $recordFeedingRoutine: Boolean!
    $recordTreatmentRoutine: Boolean!
    $BranchId: String!
  ) {
    getBranchAppointmentsViews(
      limit: $limit
      offset: $offset
      GetBranchAppointmentsViewsInput: {
        status: $status
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        billed_on_from: $billed_on_from
        billed_on_to: $billed_on_to
        filter_by_role: $filter_by_role
        PetRecordId: $PetRecordId
        booking_type: $booking_type
        appointment_id: $appointment_id
        branchAppointmentTag_name: $branchAppointmentTag_name
        appointment_branchPetRecordTag_name: $appointment_branchPetRecordTag_name
        appointment_busUserAssigned_name: $appointment_busUserAssigned_name
        appointment_product_name: $appointment_product_name
        appointment_search: $appointment_search
        appointment_timestamp_day_of_week: $appointment_timestamp_day_of_week
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
        order: $order
        order_by_busUserAssigned: $order_by_busUserAssigned
        order_status: $order_status
      }
    ) {
      count
      views {
        id
        timestamp
        timestamp_until
        status
        PetRecord {
          id
          BranchPetRecordConnections {
            id
            BranchId
            status
          }
          BranchPetRecordTags(where: { visibility: { showOnAppointments: true } }, order: "name") @include(if: $recordTags) {
            id
            name
            color
          }
          Notes(where: { visibility: { BranchId: $BranchId } }) @include(if: $recordPetNotes) {
            id
            body
          }
          Meals @include(if: $recordFeedingSchedule) {
            id
            time
            quantity
            unit
            name
            BranchCategoryId
          }
          BranchCategories @include(if: $recordFeedingRoutine) {
            id
            name
          }
          PetBehavior {
            id
            feeding_routine @include(if: $recordFeedingRoutine)
            treatment_routine @include(if: $recordTreatmentRoutine)
          }
          Pet {
            id
            name
            Breed {
              id
              name
            }
            profile_pic
            AppUser {
              id
              addresses @include(if: $recordAddress)
              postcode @include(if: $recordAddress)
            }
          }
        }
        OrderItem {
          id
          item
          quantity
          Order {
            id
            number
            total
            status
            createdAt
            payment
            AppUser {
              id
              name
            }
            Notes(where: { visibility: { BranchId: $BranchId } }) @include(if: $recordBookingNotes) {
              id
              body
            }
          }
        }
        BranchAppointmentTags {
          id
          name
          color
          capacity
        }
        BusUsers {
          id
          name
          profile_pic
        }
        BranchRooms {
          id
          name
          availability
        }
      }
    }
  }
`;

export const GetBranchCalendarAppointmentsViews = gql`
  ${APPOINTMENT_CALENDAR_EVENT_FRAGMENT}
  ${BLOCKED_SLOT_CALENDAR_EVENT_FRAGMENT}
  query getBranchCalendarAppointmentsViews(
    $status: [String]!
    $timestamp_from: String
    $timestamp_to: String
    $filter_by_role: String
    $booking_type: [String]!
    $appointment_id: [String]
    $BranchTagCategoryId: [String]
    $appointment_busUserAssigned: Boolean
    $appointment_busUserAssigned_id: [String]
    $product_branchSchedule_id: [String]
  ) {
    getBranchCalendarAppointmentsViews(
      GetBranchCalendarAppointmentsViewsInput: {
        status: $status
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        booking_type: $booking_type
        filter_by_role: $filter_by_role
        appointment_id: $appointment_id
        BranchTagCategoryId: $BranchTagCategoryId
        appointment_busUserAssigned: $appointment_busUserAssigned
        appointment_busUserAssigned_id: $appointment_busUserAssigned_id
        product_branchSchedule_id: $product_branchSchedule_id
      }
    ) {
      singleDayAppointmentsViews {
        date
        views {
          ...CalendarEventFragment
        }
      }
      multiDayAppointmentsViews {
        ...CalendarEventFragment
      }
      blockedSlotsViews {
        date
        views {
          ...BlockedSlotCalendarEventFragment
        }
      }
    }
  }
`;

export const GetBranchBusUserAssignedCalendarAppointmentsViews = gql`
  ${APPOINTMENT_CALENDAR_EVENT_FRAGMENT}
  ${BLOCKED_SLOT_CALENDAR_EVENT_FRAGMENT}
  query getBranchBusUserAssignedCalendarAppointmentsViews(
    $status: [String]!
    $timestamp_from: String
    $timestamp_to: String
    $filter_by_role: String
    $booking_type: [String]!
    $appointment_id: [String]
    $BranchTagCategoryId: [String]
    $appointment_busUserAssigned_id: [String]
  ) {
    getBranchBusUserAssignedCalendarAppointmentsViews(
      GetBranchBusUserAssignedCalendarAppointmentsViewsInput: {
        status: $status
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        booking_type: $booking_type
        filter_by_role: $filter_by_role
        appointment_id: $appointment_id
        BranchTagCategoryId: $BranchTagCategoryId
        appointment_busUserAssigned_id: $appointment_busUserAssigned_id
      }
    ) {
      busUserViews {
        BusUser {
          id
          name
        }
        views {
          singleDayAppointmentsViews {
            date
            views {
              ...CalendarEventFragment
            }
          }
          multiDayAppointmentsViews {
            ...CalendarEventFragment
          }
          blockedSlotsViews {
            date
            views {
              ...BlockedSlotCalendarEventFragment
            }
          }
        }
      }
    }
  }
`;

export const GetBranchOrderSubscriptions = gql`
  ${ORDER_SUBSCRIPTION_FRAGMENT}
  query getBranchOrderSubscriptions(
    $limit: Int
    $offset: Int
    $status: [String]!
    $PetRecordId: [String]
    $collectionStatus: [String]
    $OrderSubscriptionRepeat_createdAt_from: String
    $OrderSubscriptionRepeat_createdAt_to: String
    $OrderSubscriptionRepeat_payment_invoice_date_from: String
    $OrderSubscriptionRepeat_payment_invoice_date_to: String
    $OrderSubscriptionRepeat_status: [String]
    $subscription_search: [String]
    $subscription_product_name: [String]
    $subscription_trial_ended: Boolean
    $subscription_trial_end_from: String
    $subscription_trial_end_to: String
    $subscription_optional_trial_end_to: String
    $requisite_queries: [String]!
    $alternative_queries: [String]!
    $order_by_end_date: Boolean
    $order_by_name: Boolean
  ) {
    getBranchOrderSubscriptions(
      limit: $limit
      offset: $offset
      GetBranchOrderSubscriptionsInput: {
        status: $status
        PetRecordId: $PetRecordId
        collectionStatus: $collectionStatus
        OrderSubscriptionRepeat_createdAt_from: $OrderSubscriptionRepeat_createdAt_from
        OrderSubscriptionRepeat_createdAt_to: $OrderSubscriptionRepeat_createdAt_to
        OrderSubscriptionRepeat_payment_invoice_date_from: $OrderSubscriptionRepeat_payment_invoice_date_from
        OrderSubscriptionRepeat_payment_invoice_date_to: $OrderSubscriptionRepeat_payment_invoice_date_to
        OrderSubscriptionRepeat_status: $OrderSubscriptionRepeat_status
        subscription_search: $subscription_search
        subscription_product_name: $subscription_product_name
        subscription_trial_ended: $subscription_trial_ended
        subscription_trial_end_from: $subscription_trial_end_from
        subscription_trial_end_to: $subscription_trial_end_to
        subscription_optional_trial_end_to: $subscription_optional_trial_end_to
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
        order_by_end_date: $order_by_end_date
        order_by_name: $order_by_name
      }
    ) {
      ...OrderSubscriptionFragment
    }
  }
`;

export const GetBranchOrderSubscriptionsViews = gql`
  ${ORDER_SUBSCRIPTION_FRAGMENT}
  query getBranchOrderSubscriptionsViews(
    $limit: Int
    $offset: Int
    $status: [String]!
    $PetRecordId: [String]
    $collectionStatus: [String]
    $OrderSubscriptionRepeat_createdAt_from: String
    $OrderSubscriptionRepeat_createdAt_to: String
    $OrderSubscriptionRepeat_payment_invoice_date_from: String
    $OrderSubscriptionRepeat_payment_invoice_date_to: String
    $OrderSubscriptionRepeat_status: [String]
    $subscription_search: [String]
    $subscription_product_name: [String]
    $subscription_trial_ended: Boolean
    $subscription_trial_end_from: String
    $subscription_trial_end_to: String
    $subscription_optional_trial_end_to: String
    $requisite_queries: [String]!
    $alternative_queries: [String]!
    $order_by_end_date: Boolean
    $order_by_name: Boolean
  ) {
    getBranchOrderSubscriptionsViews(
      limit: $limit
      offset: $offset
      GetBranchOrderSubscriptionsViewsInput: {
        status: $status
        PetRecordId: $PetRecordId
        collectionStatus: $collectionStatus
        OrderSubscriptionRepeat_createdAt_from: $OrderSubscriptionRepeat_createdAt_from
        OrderSubscriptionRepeat_createdAt_to: $OrderSubscriptionRepeat_createdAt_to
        OrderSubscriptionRepeat_payment_invoice_date_from: $OrderSubscriptionRepeat_payment_invoice_date_from
        OrderSubscriptionRepeat_payment_invoice_date_to: $OrderSubscriptionRepeat_payment_invoice_date_to
        OrderSubscriptionRepeat_status: $OrderSubscriptionRepeat_status
        subscription_search: $subscription_search
        subscription_product_name: $subscription_product_name
        subscription_trial_ended: $subscription_trial_ended
        subscription_trial_end_from: $subscription_trial_end_from
        subscription_trial_end_to: $subscription_trial_end_to
        subscription_optional_trial_end_to: $subscription_optional_trial_end_to
        requisite_queries: $requisite_queries
        alternative_queries: $alternative_queries
        order_by_end_date: $order_by_end_date
        order_by_name: $order_by_name
      }
    ) {
      count
      views {
        ...OrderSubscriptionFragment
      }
    }
  }
`;

export const GetBranchOrderSubscriptionRepeats = gql`
  ${ORDER_SUBSCRIPTION_REPEAT_FRAGMENT}
  query getBranchOrderSubscriptionRepeats(
    $limit: Int
    $offset: Int
    $status: [String]
    $createdAt_from: String
    $createdAt_to: String
    $payment_invoice_date_from: String
    $payment_invoice_date_to: String
    $total_from: Float
    $total_to: Float
  ) {
    getBranchOrderSubscriptionRepeats(
      limit: $limit
      offset: $offset
      GetBranchOrderSubscriptionRepeatsInput: {
        status: $status
        createdAt_from: $createdAt_from
        createdAt_to: $createdAt_to
        payment_invoice_date_from: $payment_invoice_date_from
        payment_invoice_date_to: $payment_invoice_date_to
        total_from: $total_from
        total_to: $total_to
      }
    ) {
      ...OrderSubscriptionRepeatFragment
    }
  }
`;
export const GetBranchOrderSubscriptionsById = gql`
  ${ORDER_SUBSCRIPTION_FRAGMENT}
  query getBranchOrderSubscriptions($id: [String]!, $status: [String]!) {
    getBranchOrderSubscriptions(GetBranchOrderSubscriptionsInput: { id: $id, status: $status }) {
      ...OrderSubscriptionFragment
    }
  }
`;

export const MarkOrderSubscriptionAsReadByBranch = gql`
  mutation markOrderSubscriptionAsReadByBranch($id: String!) {
    markOrderSubscriptionAsReadByBranch(MarkOrderSubscriptionAsReadByBranchInput: { id: $id }) {
      id
    }
  }
`;

export const GetBusUserProfile = gql`
  query getBusUserProfile {
    getBusUserProfile {
      id
      name
      status
      profile_pic
      role
      Branch {
        id
        name
        additional_info
        email
        address
        photos
        longitude
        latitude
        delivery_rules
        booking_slots
        opening_hours
        payment
        branchBillingCycle {
          billing_date_from
          billing_date_to
        }
        generatedBranchBillingDates {
          billing_date_from
          billing_date_to
        }
        branchCurrency {
          currency
          symbol
        }
        max_booking_slots
        BusUsers(order: "name", where: { status: "ACTIVE" }) {
          id
          name
          profile_pic
        }
      }
      Provider {
        id
        address
        name
        contact_number
        registration_number
      }
    }
  }
`;

export const GetReducedBusUserProfile = gql`
  query getBusUserProfile {
    getBusUserProfile {
      id
      name
      status
      profile_pic
      role
      Branch {
        id
        name
        BusUsers(order: "name", where: { status: "ACTIVE" }) {
          id
          name
        }
      }
      Provider {
        id
        name
        contact_number
        Branches {
          id
          name
        }
      }
    }
  }
`;

export const GetBranchBusUsers = gql`
  ${BUS_USER_FRAGMENT}
  query getBranchBusUsers($status: [String], $id: [String], $offset: Int, $limit: Int) {
    getBranchBusUsers(offset: $offset, limit: $limit, GetBranchBusUsersInput: { status: $status, id: $id }, order: "name") {
      ...BusUserFragment
    }
  }
`;

export const GetProviderBranches = gql`
  ${BRANCH_FRAGMENT}
  query getProviderBranches($offset: Int, $limit: Int, $id: [String]) {
    getProviderBranches(offset: $offset, limit: $limit, GetProviderBranchesInput: { id: $id }) {
      ...BranchFragment
    }
  }
`;

export const EditProviderBranch = gql`
  ${BRANCH_FRAGMENT}
  mutation editProviderBranch(
    $id: String!
    $name: String
    $address: SequelizeJSON
    $contact_number: String
    $latitude: String
    $longitude: String
    $email: String
    $terms_and_conditions_url: String
    $privacy_policy_url: String
    $photos: SequelizeJSON
    $opening_hours: SequelizeJSON
    $colors: SequelizeJSON
    $payment: SequelizeJSON
    $additional_info: SequelizeJSON
    $branch_targeting: SequelizeJSON
  ) {
    editProviderBranch(
      EditProviderBranchInput: {
        id: $id
        name: $name
        address: $address
        contact_number: $contact_number
        latitude: $latitude
        longitude: $longitude
        email: $email
        terms_and_conditions_url: $terms_and_conditions_url
        privacy_policy_url: $privacy_policy_url
        photos: $photos
        opening_hours: $opening_hours
        colors: $colors
        payment: $payment
        additional_info: $additional_info
        branch_targeting: $branch_targeting
      }
    ) {
      ...BranchFragment
    }
  }
`;

export const AddBranchBusUser = gql`
  ${BUS_USER_FRAGMENT}
  mutation addBranchBusUser($name: String!, $email: String!, $password: String, $position: String, $role: String!, $status: String!, $contact_number: String, $BranchId: String) {
    addBranchBusUser(
      AddBranchBusUserInput: { name: $name, email: $email, password: $password, position: $position, role: $role, status: $status, contact_number: $contact_number, BranchId: $BranchId }
    ) {
      ...BusUserFragment
    }
  }
`;

export const EditBranchBusUser = gql`
  ${BUS_USER_FRAGMENT}
  mutation editBranchBusUser($id: String!, $name: String, $email: String!, $password: String, $position: String, $role: String, $status: String, $contact_number: String, $BranchId: String) {
    editBranchBusUser(
      EditBranchBusUserInput: { id: $id, name: $name, email: $email, password: $password, position: $position, role: $role, status: $status, contact_number: $contact_number, BranchId: $BranchId }
    ) {
      ...BusUserFragment
    }
  }
`;

export const GetBranchRooms = gql`
  ${BRANCH_ROOM_FRAGMENT}
  query getBranchRooms($id: [String], $offset: Int, $limit: Int) {
    getBranchRooms(offset: $offset, limit: $limit, GetBranchRoomsInput: { id: $id }) {
      ...BranchRoomFragment
    }
  }
`;

export const AddBranchRoom = gql`
  ${BRANCH_ROOM_FRAGMENT}
  mutation addBranchRoom($name: String!, $availability: Int) {
    addBranchRoom(AddBranchRoomInput: { name: $name, availability: $availability }) {
      ...BranchRoomFragment
    }
  }
`;

export const EditBranchRoom = gql`
  ${BRANCH_ROOM_FRAGMENT}
  mutation editBranchRoom($id: String!, $name: String, $availability: Int) {
    editBranchRoom(EditBranchRoomInput: { id: $id, name: $name, availability: $availability }) {
      ...BranchRoomFragment
    }
  }
`;

export const DeleteBranchRoom = gql`
  mutation deleteBranchRoom($id: String!) {
    deleteBranchRoom(DeleteBranchRoomInput: { id: $id }) {
      message
    }
  }
`;
export const AssignBranchRoomToAppointment = gql`
  ${BOOKINGS_APPOINTMENTS}
  mutation assignBranchRoomToAppointment($id: [String]!, $BranchRoomId: [String]) {
    assignBranchRoomToAppointment(AssignBranchRoomToAppointmentInput: { id: $id, BranchRoomId: $BranchRoomId }) {
      ...AppointmentFragment
    }
  }
`;

export const GetBranchCounts = gql`
  ${BRANCH_COUNTS_FRAGMENT}
  query getBranchCounts($timestamp_from: String, $timestamp_to: String) {
    getBranchCounts(GetBranchCountsInput: { timestamp_from: $timestamp_from, timestamp_to: $timestamp_to }) {
      ...BranchCountsFragment
    }
  }
`;

export const GetProviderBranchesCounts = gql`
  ${BRANCH_COUNTS_FRAGMENT}
  query getProviderBranchesCounts($timestamp_from: String, $timestamp_to: String) {
    getProviderBranchesCounts(GetProviderBranchesCountsInput: { timestamp_from: $timestamp_from, timestamp_to: $timestamp_to }) {
      branches {
        Branch {
          id
          name
        }
        counts {
          ...BranchCountsFragment
        }
      }
      providerCounts {
        ...BranchCountsFragment
      }
    }
  }
`;

export const GetAppUserProfileById = gql`
  ${APP_USER_FRAGMENT}
  query getAppUserById($id: String!) {
    getAppUserById(GetAppUserByIdInput: { AppUserId: $id }) {
      ...AppUserFragment
    }
  }
`;

export const GetAllBranchAppUsers = gql`
  query getBranchAppUsers {
    getBranchAppUsers(limit: 1000, order: "name") {
      id
      name
      email
      phone_numbers
      postcode
      profile_pic
      BranchAppUserTags {
        id
        name
        color
      }
    }
  }
`;

export const BatchRetryOrderInvoice = gql`
  mutation batchRetryOrderInvoice(
    $AppUserId: [String]
    $billed_on_from: String
    $billed_on_to: String
    $invoice_date_from: String
    $invoice_date_to: String
    $OrderInvoiceId: [String]
    $status: [String]
    $unbilled: Boolean
    $BranchAppUserTagId: [String]
  ) {
    batchRetryOrderInvoice(
      BatchRetryOrderInvoiceInput: {
        AppUserId: $AppUserId
        billed_on_from: $billed_on_from
        billed_on_to: $billed_on_to
        invoice_date_from: $invoice_date_from
        invoice_date_to: $invoice_date_to
        OrderInvoiceId: $OrderInvoiceId
        status: $status
        unbilled: $unbilled
        BranchAppUserTagId: $BranchAppUserTagId
      }
    ) {
      message
    }
  }
`;

export const BatchSendOrderInvoiceEmail = gql`
  mutation batchSendOrderInvoiceEmail(
    $AppUserId: [String]
    $billed_on_from: String
    $billed_on_to: String
    $invoice_date_from: String
    $invoice_date_to: String
    $OrderInvoiceId: [String]
    $status: [String]
    $unbilled: Boolean
    $BranchAppUserTagId: [String]
  ) {
    batchSendOrderInvoiceEmail(
      BatchSendOrderInvoiceEmailInput: {
        AppUserId: $AppUserId
        billed_on_from: $billed_on_from
        billed_on_to: $billed_on_to
        invoice_date_from: $invoice_date_from
        invoice_date_to: $invoice_date_to
        OrderInvoiceId: $OrderInvoiceId
        status: $status
        unbilled: $unbilled
        BranchAppUserTagId: $BranchAppUserTagId
      }
    ) {
      message
    }
  }
`;

export const AssignBusUserToAppointment = gql`
  ${BOOKINGS_APPOINTMENTS}
  mutation assignBusUserToAppointment($id: [String]!, $BusUserId: [String]) {
    assignBusUserToAppointment(AssignBusUserToAppointmentInput: { id: $id, BusUserId: $BusUserId }) {
      ...AppointmentFragment
    }
  }
`;

export const GetSearchBranchAppUsers = gql`
  query getBranchAppUsers($name: [String]) {
    getBranchAppUsers(offset: 0, limit: 1000, order: "name", GetBranchAppUsersInput: { name: $name }) {
      id
      name
      email
    }
  }
`;

export const GetBranchAppUsers = gql`
  query getBranchAppUsers(
    $limit: Int
    $offset: Int
    $billed_on_from: String
    $billed_on_to: String
    $appUserCounts_timestamp_from: String
    $appUserCounts_timestamp_to: String
    $id: [String]
    $timestamp_from: String
    $timestamp_to: String
    $order_product_type: [String]
  ) {
    getBranchAppUsers(
      limit: $limit
      offset: $offset
      GetBranchAppUsersInput: {
        billed_on_from: $billed_on_from
        billed_on_to: $billed_on_to
        appUserCounts_timestamp_from: $appUserCounts_timestamp_from
        appUserCounts_timestamp_to: $appUserCounts_timestamp_to
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        id: $id
        order_product_type: $order_product_type
      }
    ) {
      id
      name
      email
      profile_pic
      appUserCounts {
        totalAppointmentsOrdersCount
        totalAppointmentsAmount
      }
    }
  }
`;

export const GetListBranchAppUsers = gql`
  query getBranchAppUsers(
    $limit: Int
    $offset: Int
    $billed_on_from: String
    $billed_on_to: String
    $appUserCounts_timestamp_from: String
    $appUserCounts_timestamp_to: String
    $id: [String]
    $timestamp_from: String
    $timestamp_to: String
    $branchAppUserConnection_status: [String]
  ) {
    getBranchAppUsers(
      limit: $limit
      offset: $offset
      GetBranchAppUsersInput: {
        billed_on_from: $billed_on_from
        billed_on_to: $billed_on_to
        appUserCounts_timestamp_from: $appUserCounts_timestamp_from
        appUserCounts_timestamp_to: $appUserCounts_timestamp_to
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        id: $id
        branchAppUserConnection_status: $branchAppUserConnection_status
      }
    ) {
      id
      name
      email
      profile_pic
      BranchAppUserTags {
        id
        name
        color
        capacity
      }
      BranchAppUserConnections {
        id
        BranchId
        status
      }
    }
  }
`;

export const GetBranchAppUsersViews = gql`
  query getBranchAppUsersViews(
    $limit: Int
    $offset: Int
    $billed_on_from: String
    $billed_on_to: String
    $appUserCounts_timestamp_from: String
    $appUserCounts_timestamp_to: String
    $id: [String]
    $name: [String]
    $timestamp_from: String
    $timestamp_to: String
    $branchAppUserConnection_status: [String]
  ) {
    getBranchAppUsersViews(
      limit: $limit
      offset: $offset
      GetBranchAppUsersViewsInput: {
        billed_on_from: $billed_on_from
        billed_on_to: $billed_on_to
        appUserCounts_timestamp_from: $appUserCounts_timestamp_from
        appUserCounts_timestamp_to: $appUserCounts_timestamp_to
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        id: $id
        name: $name
        branchAppUserConnection_status: $branchAppUserConnection_status
      }
    ) {
      count
      views {
        id
        name
        email
        profile_pic
        BranchAppUserTags {
          id
          name
          color
          capacity
        }
        BranchAppUserConnections {
          id
          BranchId
          status
        }
      }
    }
  }
`;

export const GetReducedBranchAppUsers = gql`
  query getBranchAppUsers($limit: Int, $offset: Int, $id: [String]) {
    getBranchAppUsers(limit: $limit, offset: $offset, GetBranchAppUsersInput: { id: $id }) {
      id
      name
      email
    }
  }
`;

export const UpdateProviderByCompleteRegistrationToken = gql`
  mutation updateProviderByCompleteRegistrationToken($BusUser: SequelizeJSON!, $Provider: SequelizeJSON!, $token: String!) {
    updateProviderByCompleteRegistrationToken(UpdateProviderByCompleteRegistrationTokenInput: { BusUser: $BusUser, Provider: $Provider, token: $token }) {
      id
      name
      email
    }
  }
`;
export const GetProviderByCompleteRegistrationToken = gql`
  query getProviderByCompleteRegistrationToken($token: String!, $providerId: String!) {
    getProviderByCompleteRegistrationToken(GetProviderByCompleteRegistrationTokenInput: { token: $token, providerId: $providerId }) {
      id
      address
      name
      email
      contact_number
      registration_number
      payment
    }
  }
`;

export const SendBusUserForgetPasswordEmail = gql`
  mutation sendBusUserForgetPasswordEmail($email: String!) {
    sendBusUserForgetPasswordEmail(SendBusUserForgetPasswordEmailInput: { email: $email }) {
      message
    }
  }
`;

export const GetBusUserByForgetPasswordToken = gql`
  query getBusUserByForgetPasswordToken($userId: String!, $token: String!) {
    getBusUserByForgetPasswordToken(GetBusUserByForgetPasswordTokenInput: { userId: $userId, token: $token }) {
      id
      name
      email
    }
  }
`;

export const UpdateBusUserPasswordByForgetPasswordToken = gql`
  mutation updateBusUserPasswordByForgetPasswordToken($password: String!, $userId: String!, $token: String!) {
    updateBusUserPasswordByForgetPasswordToken(UpdateBusUserPasswordByForgetPasswordTokenInput: { password: $password, userId: $userId, token: $token }) {
      id
      name
      email
    }
  }
`;

export const NoteAdd = gql`
  ${NOTE_FRAGMENT}
  mutation noteAdd(
    $body: SequelizeJSON!
    $timestamp: Date!
    $appointmentId: String
    $medicalCaseId: String
    $groomingReportId: String
    $trainingReportId: String
    $walkingReportId: String
    $sittingReportId: String
    $daycareReportId: String
  ) {
    noteAdd(
      Note: {
        body: $body
        timestamp: $timestamp
        AppointmentId: $appointmentId
        MedCondId: $medicalCaseId
        GroomingReportId: $groomingReportId
        TrainingReportId: $trainingReportId
        WalkingReportId: $walkingReportId
        SittingReportId: $sittingReportId
        DaycareReportId: $daycareReportId
      }
    ) {
      ...NoteFragment
    }
  }
`;

export const NoteEdit = gql`
  ${NOTE_FRAGMENT}
  mutation noteEdit($id: String!, $body: SequelizeJSON!, $timestamp: Date!) {
    noteEdit(Note: { id: $id, body: $body, timestamp: $timestamp }) {
      ...NoteFragment
    }
  }
`;

export const NoteDelete = gql`
  mutation noteDelete($id: String!) {
    noteDelete(id: $id)
  }
`;

export const GetMedCondsByPetRecordId = gql`
  ${MED_CONDS_FRAGMENT}
  query getMedCondsByPetRecordId($offset: Int, $petRecordId: String!) {
    getMedCondsByPetRecordId(limit: 6, offset: $offset, GetMedCondsByPetRecordIdInput: { petRecordId: $petRecordId }) {
      ...MedCondFragment
    }
  }
`;

export const GetGroomingReportsByPetRecordId = gql`
  ${GROOMING_REPORT_FRAGMENT}
  query getGroomingReportsByPetRecordId($offset: Int, $petRecordId: String!) {
    getGroomingReportsByPetRecordId(limit: 6, offset: $offset, GetGroomingReportsByPetRecordIdInput: { petRecordId: $petRecordId }) {
      ...GroomingReportFragment
    }
  }
`;

export const GetTrainingReportsByPetRecordId = gql`
  ${TRAINING_REPORT_FRAGMENT}
  query getTrainingReportsByPetRecordId($offset: Int, $petRecordId: String!) {
    getTrainingReportsByPetRecordId(limit: 6, offset: $offset, GetTrainingReportsByPetRecordIdInput: { petRecordId: $petRecordId }) {
      ...TrainingReportFragment
    }
  }
`;

export const GetWalkingReportsByPetRecordId = gql`
  ${WALKING_REPORT_FRAGMENT}
  query getWalkingReportsByPetRecordId($offset: Int, $petRecordId: String!) {
    getWalkingReportsByPetRecordId(limit: 6, offset: $offset, GetWalkingReportsByPetRecordIdInput: { petRecordId: $petRecordId }) {
      ...WalkingReportFragment
    }
  }
`;

export const GetSittingReportsByPetRecordId = gql`
  ${SITTING_REPORT_FRAGMENT}
  query getSittingReportsByPetRecordId($offset: Int, $petRecordId: String!) {
    getSittingReportsByPetRecordId(limit: 6, offset: $offset, GetSittingReportsByPetRecordIdInput: { petRecordId: $petRecordId }) {
      ...SittingReportFragment
    }
  }
`;

export const getDaycareReportsByPetRecordId = gql`
  ${DAYCARE_REPORT_FRAGMENT}
  query getDaycareReportsByPetRecordId($offset: Int, $petRecordId: String!) {
    getDaycareReportsByPetRecordId(limit: 6, offset: $offset, GetDaycareReportsByPetRecordIdInput: { petRecordId: $petRecordId }) {
      ...DaycareReportFragment
    }
  }
`;

export const MedCondAdd = gql`
  ${MED_CONDS_FRAGMENT}
  mutation medCondAdd($name: String!, $description: String, $petRecordId: String!) {
    medCondAdd(MedCond: { name: $name, description: $description, PetRecordId: $petRecordId }) {
      ...MedCondFragment
    }
  }
`;

export const GroomingReportAdd = gql`
  ${GROOMING_REPORT_FRAGMENT}
  mutation groomingReportAdd($name: String!, $description: String, $petRecordId: String!, $appointmentId: String) {
    groomingReportAdd(GroomingReport: { name: $name, description: $description, PetRecordId: $petRecordId, AppointmentId: $appointmentId }) {
      ...GroomingReportFragment
    }
  }
`;

export const TrainingReportAdd = gql`
  ${TRAINING_REPORT_FRAGMENT}
  mutation trainingReportAdd($name: String!, $description: String, $petRecordId: String!, $appointmentId: String) {
    trainingReportAdd(TrainingReport: { name: $name, description: $description, PetRecordId: $petRecordId, AppointmentId: $appointmentId }) {
      ...TrainingReportFragment
    }
  }
`;

export const WalkingReportAdd = gql`
  ${WALKING_REPORT_FRAGMENT}
  mutation walkingReportAdd($name: String!, $description: String, $petRecordId: String!, $appointmentId: String) {
    walkingReportAdd(WalkingReport: { name: $name, description: $description, PetRecordId: $petRecordId, AppointmentId: $appointmentId }) {
      ...WalkingReportFragment
    }
  }
`;

export const SittingReportAdd = gql`
  ${SITTING_REPORT_FRAGMENT}
  mutation sittingReportAdd($name: String!, $description: String, $petRecordId: String!, $appointmentId: String) {
    sittingReportAdd(SittingReport: { name: $name, description: $description, PetRecordId: $petRecordId, AppointmentId: $appointmentId }) {
      ...SittingReportFragment
    }
  }
`;

export const DaycareReportAdd = gql`
  ${DAYCARE_REPORT_FRAGMENT}
  mutation daycareReportAdd($name: String!, $description: String, $petRecordId: String!, $appointmentId: String) {
    daycareReportAdd(DaycareReport: { name: $name, description: $description, PetRecordId: $petRecordId, AppointmentId: $appointmentId }) {
      ...DaycareReportFragment
    }
  }
`;

export const MedCondEdit = gql`
  ${MED_CONDS_FRAGMENT}
  mutation medCondEdit($name: String!, $description: String, $id: String!) {
    medCondEdit(MedCond: { name: $name, description: $description, id: $id }) {
      ...MedCondFragment
    }
  }
`;

export const GroomingReportEdit = gql`
  ${GROOMING_REPORT_FRAGMENT}
  mutation groomingReportEdit($name: String!, $description: String, $id: String!, $appointmentId: String) {
    groomingReportEdit(GroomingReport: { name: $name, description: $description, id: $id, AppointmentId: $appointmentId }) {
      ...GroomingReportFragment
    }
  }
`;

export const TrainingReportEdit = gql`
  ${TRAINING_REPORT_FRAGMENT}
  mutation trainingReportEdit($name: String!, $description: String, $id: String!, $appointmentId: String) {
    trainingReportEdit(TrainingReport: { name: $name, description: $description, id: $id, AppointmentId: $appointmentId }) {
      ...TrainingReportFragment
    }
  }
`;

export const WalkingReportEdit = gql`
  ${WALKING_REPORT_FRAGMENT}
  mutation walkingReportEdit($name: String!, $description: String, $id: String!, $appointmentId: String) {
    walkingReportEdit(WalkingReport: { name: $name, description: $description, id: $id, AppointmentId: $appointmentId }) {
      ...WalkingReportFragment
    }
  }
`;

export const SittingReportEdit = gql`
  ${SITTING_REPORT_FRAGMENT}
  mutation sittingReportEdit($name: String!, $description: String, $id: String!, $appointmentId: String) {
    sittingReportEdit(SittingReport: { name: $name, description: $description, id: $id, AppointmentId: $appointmentId }) {
      ...SittingReportFragment
    }
  }
`;

export const DaycareReportEdit = gql`
  ${DAYCARE_REPORT_FRAGMENT}
  mutation daycareReportEdit($name: String!, $description: String, $id: String!, $appointmentId: String) {
    daycareReportEdit(DaycareReport: { name: $name, description: $description, id: $id, AppointmentId: $appointmentId }) {
      ...DaycareReportFragment
    }
  }
`;

export const GetNotesByMedCondId = gql`
  ${NOTE_FRAGMENT}
  query getNotesByMedCondId($offset: Int, $medCondId: [String!]!, $limit: Int) {
    getNotesByMedCondId(limit: $limit, offset: $offset, GetNotesByMedCondIdInput: { MedCondId: $medCondId }) {
      ...NoteFragment
    }
  }
`;

export const GetNotesByGroomingReportId = gql`
  ${NOTE_FRAGMENT}
  query getNotesByGroomingReportId($offset: Int, $groomingReportId: [String!]!, $limit: Int) {
    getNotesByGroomingReportId(limit: $limit, offset: $offset, GetNotesByGroomingReportIdInput: { GroomingReportId: $groomingReportId }) {
      ...NoteFragment
    }
  }
`;

export const GetNotesByTrainingReportId = gql`
  ${NOTE_FRAGMENT}
  query getNotesByTrainingReportId($offset: Int, $trainingReportId: [String!]!, $limit: Int) {
    getNotesByTrainingReportId(limit: $limit, offset: $offset, GetNotesByTrainingReportIdInput: { TrainingReportId: $trainingReportId }) {
      ...NoteFragment
    }
  }
`;

export const GetNotesByWalkingReportId = gql`
  ${NOTE_FRAGMENT}
  query getNotesByWalkingReportId($offset: Int, $walkingReportId: [String!]!, $limit: Int) {
    getNotesByWalkingReportId(limit: $limit, offset: $offset, GetNotesByWalkingReportIdInput: { WalkingReportId: $walkingReportId }) {
      ...NoteFragment
    }
  }
`;

export const GetNotesBySittingReportId = gql`
  ${NOTE_FRAGMENT}
  query getNotesBySittingReportId($offset: Int, $sittingReportId: [String!]!, $limit: Int) {
    getNotesBySittingReportId(limit: $limit, offset: $offset, GetNotesBySittingReportIdInput: { SittingReportId: $sittingReportId }) {
      ...NoteFragment
    }
  }
`;

export const GetNotesByDaycareReportId = gql`
  ${NOTE_FRAGMENT}
  query getNotesByDaycareReportId($offset: Int, $daycareReportId: [String!]!, $limit: Int) {
    getNotesByDaycareReportId(limit: $limit, offset: $offset, GetNotesByDaycareReportIdInput: { DaycareReportId: $daycareReportId }) {
      ...NoteFragment
    }
  }
`;

export const GetAllMedCondsByPetRecordId = gql`
  query getMedCondsByPetRecordId($petRecordId: String!) {
    getMedCondsByPetRecordId(limit: 1000, offset: 0, GetMedCondsByPetRecordIdInput: { petRecordId: $petRecordId }) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const GetAllGroomingReportsByPetRecordId = gql`
  ${GROOMING_REPORT_FRAGMENT}
  query getGroomingReportsByPetRecordId($petRecordId: String!) {
    getGroomingReportsByPetRecordId(limit: 1000, offset: 0, GetGroomingReportsByPetRecordIdInput: { petRecordId: $petRecordId }) {
      ...GroomingReportFragment
    }
  }
`;

export const GetAllTrainingReportsByPetRecordId = gql`
  ${TRAINING_REPORT_FRAGMENT}
  query getTrainingReportsByPetRecordId($petRecordId: String!) {
    getTrainingReportsByPetRecordId(limit: 1000, offset: 0, GetTrainingReportsByPetRecordIdInput: { petRecordId: $petRecordId }) {
      ...TrainingReportFragment
    }
  }
`;

export const GetAllWalkingReportsByPetRecordId = gql`
  ${WALKING_REPORT_FRAGMENT}
  query getWalkingReportsByPetRecordId($petRecordId: String!) {
    getWalkingReportsByPetRecordId(limit: 1000, offset: 0, GetWalkingReportsByPetRecordIdInput: { petRecordId: $petRecordId }) {
      ...WalkingReportFragment
    }
  }
`;

export const GetAllSittingReportsByPetRecordId = gql`
  ${SITTING_REPORT_FRAGMENT}
  query getSittingReportsByPetRecordId($petRecordId: String!) {
    getSittingReportsByPetRecordId(limit: 1000, offset: 0, GetSittingReportsByPetRecordIdInput: { petRecordId: $petRecordId }) {
      ...SittingReportFragment
    }
  }
`;

export const GetAllDaycareReportsByPetRecordId = gql`
  ${DAYCARE_REPORT_FRAGMENT}
  query getDaycareReportsByPetRecordId($petRecordId: String!) {
    getDaycareReportsByPetRecordId(limit: 1000, offset: 0, GetDaycareReportsByPetRecordIdInput: { petRecordId: $petRecordId }) {
      ...DaycareReportFragment
    }
  }
`;

export const GetPetRecordReports = gql`
  ${MED_CONDS_FRAGMENT}
  ${GROOMING_REPORT_FRAGMENT}
  ${TRAINING_REPORT_FRAGMENT}
  ${WALKING_REPORT_FRAGMENT}
  ${SITTING_REPORT_FRAGMENT}
  ${DAYCARE_REPORT_FRAGMENT}
  query getPetRecordReports($id: [String!], $petRecordId: [String!]) {
    getPetRecordReports(GetPetRecordReportsInput: { id: $id, petRecordId: $petRecordId }) {
      MedConds {
        ...MedCondFragment
      }
      GroomingReports {
        ...GroomingReportFragment
      }
      TrainingReports {
        ...TrainingReportFragment
      }
      WalkingReports {
        ...WalkingReportFragment
      }
      SittingReports {
        ...SittingReportFragment
      }
      DaycareReports {
        ...DaycareReportFragment
      }
    }
  }
`;

export const GetPetRecordRecords = gql`
  ${VACC_RECORD_FRAGMENT}
  ${SUPP_RECORD_FRAGMENT}
  ${ADHERENCE_FRAGMENT}
  query getPetRecordRecords($MedCondId: [String], $PetRecordId: [String], $filter_by_active: Boolean) {
    getPetRecordRecords(GetPetRecordRecordsInput: { MedCondId: $MedCondId, PetRecordId: $PetRecordId, filter_by_active: $filter_by_active }, offset: 0, limit: 1000) {
      VaccRecords {
        ...VaccRecordFragment
      }
      SuppRecords {
        ...SuppRecordFragment
      }
      Adherences {
        ...AdherenceFragment
      }
    }
  }
`;

export const GetPetRecordRecordsVaccRecords = gql`
  ${VACC_RECORD_FRAGMENT}
  query getPetRecordRecords($MedCondId: [String], $PetRecordId: [String], $filter_by_active: Boolean, $limit: Int, $offset: Int) {
    getPetRecordRecords(GetPetRecordRecordsInput: { MedCondId: $MedCondId, PetRecordId: $PetRecordId, filter_by_active: $filter_by_active }, offset: $offset, limit: $limit) {
      VaccRecords {
        ...VaccRecordFragment
      }
    }
  }
`;

export const GetAllPetRecordRecordsVaccRecords = gql`
  ${VACC_RECORD_FRAGMENT}
  query getPetRecordRecords($MedCondId: [String], $PetRecordId: [String], $filter_by_active: Boolean) {
    getPetRecordRecords(GetPetRecordRecordsInput: { MedCondId: $MedCondId, PetRecordId: $PetRecordId, filter_by_active: $filter_by_active }, offset: 0, limit: 1000) {
      VaccRecords {
        ...VaccRecordFragment
      }
    }
  }
`;

export const GetAllPetRecordRecordsSuppRecords = gql`
  ${SUPP_RECORD_FRAGMENT}
  ${ADHERENCE_FRAGMENT}
  query getPetRecordRecords($MedCondId: [String], $PetRecordId: [String], $filter_by_active: Boolean) {
    getPetRecordRecords(GetPetRecordRecordsInput: { MedCondId: $MedCondId, PetRecordId: $PetRecordId, filter_by_active: $filter_by_active }, offset: 0, limit: 1000) {
      SuppRecords {
        ...SuppRecordFragment
      }
      Adherences {
        ...AdherenceFragment
      }
    }
  }
`;

export const EditVaccRecord = gql`
  ${VACC_RECORD_FRAGMENT}
  mutation editVaccRecord($VaccRecordId: [String]!, $VaccRecord: VaccRecordInput) {
    editVaccRecord(VaccRecordEditInput: { VaccRecordId: $VaccRecordId, VaccRecord: $VaccRecord }) {
      ...VaccRecordFragment
    }
  }
`;

export const EditSuppRecord = gql`
  ${SUPP_RECORD_FRAGMENT}
  mutation editSuppRecord($SuppRecordId: [String]!, $SuppRecord: SuppRecordInput) {
    editSuppRecord(SuppRecordEditInput: { SuppRecordId: $SuppRecordId, SuppRecord: $SuppRecord }) {
      ...SuppRecordFragment
    }
  }
`;

export const GetProductKinds = gql`
  query productKindGet {
    productKindGet(order: "name") {
      id
      name
    }
  }
`;

export const GetAllBreeds = gql`
  query getBreeds {
    getBreeds(order: "name", limit: 1000) {
      type
      name
      id
    }
  }
`;

export const GetBreeds = gql`
  query getBreeds($name: [String], $type: [String], $id: [String]) {
    getBreeds(order: "name", limit: 1000, GetBreedsInput: { name: $name, type: $type, id: $id }) {
      type
      name
      id
    }
  }
`;

export const GetBranchCategoryByName = gql`
  query branchCategoryGet($name: String!) {
    branchCategoryGet(where: { name: $name }) {
      id
      Branches(order: "name") {
        id
        name
      }
    }
  }
`;

export const GetAllBranchCategories = gql`
  query branchCategoryGet {
    branchCategoryGet {
      id
      name
    }
  }
`;

export const GetSearchBranchCategoryVets = gql`
  query branchCategoryGet($search: String!) {
    branchCategoryGet(where: { name: "Vet" }) {
      id
      Branches(where: { name: { substring: $search } }, order: "name") {
        id
        name
      }
    }
  }
`;

export const GetVetById = gql`
  query branchCategoryGet($id: String!) {
    branchCategoryGet(where: { name: "Vet" }) {
      id
      Branches(where: { id: $id }, order: "name") {
        id
        name
      }
    }
  }
`;

// export const GetBranchCategoriesAndMealPortions = gql`
//   query branchCategoryGet(
//     $petType: String
//     ) {
//     branchCategoryGet(where: { or: [{ name: "Dry" }, { name: "Raw" }, { name: "Fresh" }, { name: "Wet" }] }) {
//       id
//       name
//       picture
//     }
//     mealPortionGet(order: "age", where: { pet_type: $petType }) {
//       id
//       age
//       pet_type
//       activity
//       multiplier
//       meal_count
//       BranchCategoryId
//     }
//   }
// `;

export const GetBranchCategoriesAndMealPortions = gql`
  query branchCategoryGet {
    branchCategoryGet(where: { or: [{ name: "Dry" }, { name: "Raw" }, { name: "Fresh" }, { name: "Wet" }] }) {
      id
      name
    }
  }
`;

export const AddBranchPetRecordItem = gql`
  mutation branchPetRecordItemAdd($branchId: String!, $PetRecordId: String!, $type: BranchPetRecordItemtypeEnumType, $days: [String]) {
    branchPetRecordItemAdd(BranchPetRecordItem: { PetRecordId: $PetRecordId, BranchId: $branchId, type: $type, days: $days }) {
      id
      PetRecordId
      BranchId
      type
      days
    }
  }
`;

export const EditBranchPetRecordItem = gql`
  mutation branchPetRecordItemEdit($id: String!, $branchId: String!, $PetRecordId: String!, $type: BranchPetRecordItemtypeEnumType, $days: [String]) {
    branchPetRecordItemEdit(BranchPetRecordItem: { id: $id, PetRecordId: $PetRecordId, BranchId: $branchId, type: $type, days: $days }) {
      id
      PetRecordId
      BranchId
      type
      days
    }
  }
`;

export const DeleteBranchPetRecordItem = gql`
  mutation deleteBranchPetRecordItem($id: String!) {
    deleteBranchPetRecordItem(DeleteBranchPetRecordItemInput: { id: $id }) {
      message
    }
  }
`;

export const GetBranchesByProductKind = gql`
  query getBranchesByProductKind($productKindId: String!) {
    getBranchesByProductKind(ProductKindIdInput: { id: $productKindId }, order: "name") {
      id
      name
    }
  }
`;

export const GetProductsByBranchId = gql`
  query productGet($branchId: String!) {
    productGet(where: { BranchId: $branchId }, order: "name") {
      id
      name
      type
      unit
      price
      weight
    }
  }
`;

export const GetServicesByBranchId = gql`
  query productGet($branchId: String!) {
    productGet(where: { BranchId: $branchId, type: ["service"] }, order: "name") {
      id
      name
      type
      unit
      price
      weight
    }
  }
`;

export const GetServicesAndSubscriptionsByBranchId = gql`
  query productGet($branchId: String!) {
    productGet(where: { BranchId: $branchId, type: ["service", "subscription"] }, order: "name") {
      id
      name
      type
    }
  }
`;

export const GetAllProducts = gql`
  query productGet {
    productGet(order: "name") {
      id
      name
      type
      unit
      price
      weight
      ProductKinds {
        id
        name
      }
    }
  }
`;

export const GetAllBranchAppUsersUsedProducts = gql`
  query getBranchAppUsersUsedProducts {
    getBranchAppUsersUsedProducts {
      id
      name
      type
      unit
      price
      weight
      ProductKinds {
        id
        name
      }
    }
  }
`;

export const AddSupplement = gql`
  ${SUPP_RECORD_FRAGMENT}
  mutation suppRecordAdd($type: String, $product: String, $quantity: String!, $time: String!, $PetRecordId: String!, $start_date: Date, $adherences_allowed: Boolean, $count: Int, $end_date: Date) {
    suppRecordAdd(
      SuppRecord: {
        type: $type
        ProductId: $product
        quantity: $quantity
        time: $time
        PetRecordId: $PetRecordId
        start_date: $start_date
        adherences_allowed: $adherences_allowed
        count: $count
        end_date: $end_date
      }
    ) {
      ...SuppRecordFragment
    }
  }
`;

export const EditSupplement = gql`
  ${SUPP_RECORD_FRAGMENT}
  mutation suppRecordEdit(
    $id: String!
    $type: String
    $product: String
    $quantity: String
    $time: String
    $PetRecordId: String
    $start_date: Date
    $adherences_allowed: Boolean
    $count: Int
    $end_date: Date
  ) {
    suppRecordEdit(
      SuppRecord: {
        id: $id
        type: $type
        ProductId: $product
        quantity: $quantity
        time: $time
        PetRecordId: $PetRecordId
        start_date: $start_date
        adherences_allowed: $adherences_allowed
        count: $count
        end_date: $end_date
      }
    ) {
      ...SuppRecordFragment
    }
  }
`;

export const DeleteSupplement = gql`
  mutation deleteSuppRecord($id: String!) {
    deleteSuppRecord(SuppRecordDeleteInput: { id: $id }) {
      message
    }
  }
`;

export const AddRemedy = gql`
  mutation remedyAdd($MedCondId: String!, $SuppRecordId: String!) {
    remedyAdd(Remedy: { MedCondId: $MedCondId, SuppRecordId: $SuppRecordId }) {
      id
    }
  }
`;

export const EditPetRecord = gql`
  ${PETRECORD_FRAGMENT}
  mutation editPetRecord(
    $id: String!
    $measurements: SequelizeJSON
    $allergies: SequelizeJSON
    $chronic_conditions: SequelizeJSON
    $disabilities: SequelizeJSON
    $shape: PetRecordshapeEnumType
    $neutred: Boolean
    $microchip_number: String
    $microchip_provider: String
    $branchId: String
    $vet_name: String
    $vet: SequelizeJSON
    $insurer: SequelizeJSON
  ) {
    editPetRecord(
      PetRecord: {
        id: $id
        measurements: $measurements
        allergies: $allergies
        chronic_conditions: $chronic_conditions
        disabilities: $disabilities
        shape: $shape
        neutred: $neutred
        microchip_number: $microchip_number
        microchip_provider: $microchip_provider
        BranchId: $branchId
        vet_name: $vet_name
        vet: $vet
        insurer: $insurer
      }
    ) {
      ...PetRecordFragment
    }
  }
`;

export const AddBranchPet = gql`
  ${PET_FRAGMENT}
  mutation addBranchPet(
    $type: String!
    $name: String!
    $birthdate: String!
    $gender: String!
    $profile_pic: String
    $AppUserId: String!
    $BreedId: String!
    $PetColorId: String
    $petRecord_neutred: Boolean!
  ) {
    addBranchPet(
      AddBranchPetInput: {
        type: $type
        name: $name
        birthdate: $birthdate
        gender: $gender
        profile_pic: $profile_pic
        AppUserId: $AppUserId
        BreedId: $BreedId
        PetColorId: $PetColorId
        petRecord_neutred: $petRecord_neutred
      }
    ) {
      ...PetFragment
    }
  }
`;

export const EditBranchPet = gql`
  ${PET_FRAGMENT}
  mutation editBranchPet($id: String!, $type: String, $name: String, $profile_pic: String, $gender: String, $birthdate: String, $BreedId: String, $PetColorId: String) {
    editBranchPet(EditBranchPetInput: { id: $id, type: $type, name: $name, profile_pic: $profile_pic, birthdate: $birthdate, gender: $gender, BreedId: $BreedId, PetColorId: $PetColorId }) {
      ...PetFragment
    }
  }
`;

export const GetVaccRecordTypes = gql`
  query vaccRecordTypeGet {
    vaccRecordTypeGet(order: "name") {
      id
      name
    }
  }
`;

export const GetProductKindsWithProducts = gql`
  query productKindGet($name: [String]) {
    productKindGet(limit: 100, where: { name: $name }, order: "name") {
      id
      name
      Products(limit: 100) {
        id
        name
      }
    }
  }
`;

export const AddVaccination = gql`
  ${VACC_RECORD_FRAGMENT}
  mutation vaccRecordAdd($color: String!, $dates: [Date]!, $nextdate: Date, $name: String!, $VaccRecordTypeId: String!, $PetRecordId: String!, $batch_number: String, $end_date: Date) {
    vaccRecordAdd(
      VaccRecord: { color: $color, dates: $dates, nextdate: $nextdate, name: $name, VaccRecordTypeId: $VaccRecordTypeId, PetRecordId: $PetRecordId, batch_number: $batch_number, end_date: $end_date }
    ) {
      ...VaccRecordFragment
    }
  }
`;

export const EditVaccination = gql`
  ${VACC_RECORD_FRAGMENT}
  mutation vaccRecordEdit($id: String!, $color: String, $dates: [Date], $nextdate: Date, $name: String, $VaccRecordTypeId: String, $batch_number: String, $end_date: Date) {
    vaccRecordEdit(VaccRecord: { id: $id, color: $color, dates: $dates, nextdate: $nextdate, name: $name, VaccRecordTypeId: $VaccRecordTypeId, batch_number: $batch_number, end_date: $end_date }) {
      ...VaccRecordFragment
    }
  }
`;

export const DeleteVaccination = gql`
  mutation deleteVaccRecord($id: String!) {
    deleteVaccRecord(VaccRecordDeleteInput: { id: $id }) {
      message
    }
  }
`;

export const DeleteMedCondVaccRecordItem = gql`
  mutation deleteMedCondVaccRecordItem($MedCondId: String!, $VaccRecordId: String!) {
    deleteMedCondVaccRecordItem(DeleteMedCondVaccRecordItemInput: { MedCondId: $MedCondId, VaccRecordId: $VaccRecordId }) {
      message
    }
  }
`;

export const AddMedCondVaccRecordItem = gql`
  mutation addMedCondVaccRecordItem($MedCondId: String!, $VaccRecordId: String!) {
    medCondVaccRecordItemAdd(MedCondVaccRecordItem: { MedCondId: $MedCondId, VaccRecordId: $VaccRecordId }) {
      id
    }
  }
`;

export const GetPetColors = gql`
  query getPetColors($name: [String], $type: [String], $id: [String]) {
    getPetColors(limit: 1000, order: "name", GetPetColorsInput: { name: $name, type: $type, id: $id }) {
      type
      name
      id
    }
  }
`;

export const UpdateMessageReadStatus = gql`
  ${MESSAGE_READ_STATUS_FRAGMENT}
  mutation updateMessageReadStatus($status: MessageReadStatusstatusEnumType, $last_read_message: String, $last_read_timestamp: Date, $ChatRoomId: String, $BranchId: String) {
    updateMessageReadStatus(MessageReadStatus: { status: $status, last_read_message: $last_read_message, last_read_timestamp: $last_read_timestamp, ChatRoomId: $ChatRoomId, BranchId: $BranchId }) {
      ...MessageReadStatusFragment
    }
  }
`;

export const AddAdherence = gql`
  ${ADHERENCE_FRAGMENT}
  mutation adherenceAdd($PetRecordId: String!, $SuppRecordId: String, $VaccRecordId: String, $MedCondId: String, $timestamp: Date!) {
    adherenceAdd(Adherence: { PetRecordId: $PetRecordId, SuppRecordId: $SuppRecordId, VaccRecordId: $VaccRecordId, MedCondId: $MedCondId, timestamp: $timestamp, status: true }) {
      ...AdherenceFragment
    }
  }
`;

export const AddBranchSlot = gql`
  mutation addBranchSlot($timestamp: String!, $count: Int!, $duration: Int!, $description: String!, $branch_schedules: [String]!, $status: String!) {
    addBranchSlot(AddBranchSlotInput: { timestamp: $timestamp, count: $count, duration: $duration, description: $description, branch_schedules: $branch_schedules, status: $status }) {
      id
      timestamp
      status
      count
      duration
      description
      BranchSchedules {
        id
        name
      }
    }
  }
`;

export const EditBranchSlot = gql`
  mutation editBranchSlot($id: String!, $timestamp: String, $count: Int, $duration: Int, $description: String, $branch_schedules: [String], $status: String) {
    editBranchSlot(EditBranchSlotInput: { id: $id, timestamp: $timestamp, count: $count, duration: $duration, description: $description, branch_schedules: $branch_schedules, status: $status }) {
      id
      timestamp
      status
      count
      duration
      description
      BranchSchedules {
        id
        name
      }
    }
  }
`;

export const GetBranchSlots = gql`
  query getBranchSlots($id: [String]) {
    getBranchSlots(GetBranchSlotsInput: { id: $id }) {
      id
      timestamp
      status
      count
      duration
      description
      BranchSchedules {
        id
        name
      }
    }
  }
`;

export const DeleteBranchSlot = gql`
  mutation deleteBranchSlot($id: String!) {
    deleteBranchSlot(DeleteBranchSlotInput: { id: $id }) {
      message
    }
  }
`;

export const StartOrderForAppUser = gql`
  mutation startOrderForAppUser(
    $items: [SequelizeJSON]
    $vouchers: [SequelizeJSON]
    $appointments: [SequelizeJSON]
    $subscriptions: [SequelizeJSON]
    $delivery: [SequelizeJSON]
    $AppUserId: String!
    $overriddenTotal: String
    $autoConfirm: Boolean
    $autoConfirmAppointments: Boolean
    $markProductNoCharge: Boolean
    $BusUserId: [String]
    $BranchRoomId: [String]
    $ignoreStock: Boolean
    $disableNotifications: Boolean
    $paymentOffSession: Boolean
  ) {
    startOrderForAppUser(
      StartOrderForAppUserInput: {
        items: $items
        vouchers: $vouchers
        appointments: $appointments
        subscriptions: $subscriptions
        delivery: $delivery
        AppUserId: $AppUserId
        overriddenTotal: $overriddenTotal
        autoConfirm: $autoConfirm
        autoConfirmAppointments: $autoConfirmAppointments
        markProductNoCharge: $markProductNoCharge
        BusUserId: $BusUserId
        BranchRoomId: $BranchRoomId
        ignoreStock: $ignoreStock
        disableNotifications: $disableNotifications
        paymentOffSession: $paymentOffSession
      }
    ) {
      id
      Products {
        name
        id
      }
    }
  }
`;

export const StartRecurringOrderForAppUser = gql`
  mutation startRecurringOrderForAppUser(
    $weeks_count: Int!
    $start_date: String!
    $day_of_week: [Int]!
    $PetRecordId: [String]!
    $ProductId: String!
    $customizations: SequelizeJSON
    $AppUserId: String!
    $overriddenTotal: String
    $autoConfirm: Boolean
    $autoConfirmAppointments: Boolean
    $markProductNoCharge: Boolean
    $BusUserId: [String]
    $ignoreStock: Boolean
    $disableNotifications: Boolean
  ) {
    startRecurringOrderForAppUser(
      StartRecurringOrderForAppUserInput: {
        weeks_count: $weeks_count
        start_date: $start_date
        day_of_week: $day_of_week
        PetRecordId: $PetRecordId
        ProductId: $ProductId
        AppUserId: $AppUserId
        overriddenTotal: $overriddenTotal
        autoConfirm: $autoConfirm
        autoConfirmAppointments: $autoConfirmAppointments
        markProductNoCharge: $markProductNoCharge
        BusUserId: $BusUserId
        ignoreStock: $ignoreStock
        customizations: $customizations
        disableNotifications: $disableNotifications
      }
    ) {
      id
      Products {
        name
        id
      }
    }
  }
`;

export const StartMultiOrderForAppUser = gql`
  mutation startMultiOrderForAppUser($startMultiOrder: [StartOrderForAppUserInput], $AppUserId: String!) {
    startMultiOrderForAppUser(StartMultiOrderForAppUserInput: { startMultiOrder: $startMultiOrder, AppUserId: $AppUserId }) {
      id
    }
  }
`;

export const GetPreOrderSummaryForAppUser = gql`
  query getPreOrderSummaryForAppUser(
    $items: [SequelizeJSON]
    $vouchers: [SequelizeJSON]
    $appointments: [SequelizeJSON]
    $subscriptions: [SequelizeJSON]
    $delivery: [SequelizeJSON]
    $AppUserId: String!
  ) {
    getPreOrderSummaryForAppUser(
      GetPreOrderSummaryForAppUserInput: { items: $items, vouchers: $vouchers, appointments: $appointments, subscriptions: $subscriptions, delivery: $delivery, AppUserId: $AppUserId }
    ) {
      discount
      delivery
      finalPrices {
        finalPriceWithDelivery
      }
      isAlreadyBooked
      isAlreadySubscribed
      total
      creditToBeUsed
      productsWithTotals {
        productTotals {
          priceSummary
        }
      }
    }
  }
`;

export const GetMultiOrderPreOrderSummaryForAppUser = gql`
  query getMultiOrderPreOrderSummaryForAppUser($AppUserId: String!, $getMultiOrderPreOrderSummary: [GetPreOrderSummaryForAppUserInput]) {
    getMultiOrderPreOrderSummaryForAppUser(GetMultiOrderPreOrderSummaryForAppUserInput: { getMultiOrderPreOrderSummary: $getMultiOrderPreOrderSummary, AppUserId: $AppUserId }) {
      summariesTotal {
        discount
        finalPrices {
          finalPriceWithDelivery
        }
        total
      }
      summaries {
        discount
        isAlreadyBooked
        creditToBeUsed
        finalPrices {
          finalPriceWithDelivery
        }
        total
      }
    }
  }
`;

export const GetRecurringPreOrderSummaryForAppUser = gql`
  query getRecurringPreOrderSummaryForAppUser(
    $weeks_count: Int!
    $start_date: String!
    $day_of_week: [Int]!
    $PetRecordId: [String]!
    $ProductId: String!
    $AppUserId: String!
    $customizations: SequelizeJSON
  ) {
    getRecurringPreOrderSummaryForAppUser(
      GetRecurringPreOrderSummaryForAppUserInput: {
        weeks_count: $weeks_count
        start_date: $start_date
        day_of_week: $day_of_week
        PetRecordId: $PetRecordId
        ProductId: $ProductId
        customizations: $customizations
        AppUserId: $AppUserId
      }
    ) {
      summariesTotal {
        discount
        finalPrices {
          finalPriceWithDelivery
        }
        total
      }
      summaries {
        discount
        isAlreadyBooked
        creditToBeUsed
        finalPrices {
          finalPriceWithDelivery
        }
        total
      }
      timestamps
    }
  }
`;

export const GetProductById = gql`
  query productGet($id: [String]!) {
    productGet(where: { id: { in: $id } }) {
      id
      name
      BranchId
      sort_index
      price
      description
      short_description
      duration
      unit
      charge_type
      type
      photos
      use_pets_count
      allows_repeated_orders
      allows_bulk_orders
      max_bulk_orders
      payment
      ProductKinds {
        id
        name
      }
      PharmaItemProducts {
        id
        PharmaItem {
          id
          pack_size
        }
      }
      customizations
      cta_text
      Flags {
        id
        name
      }
      sessions
      status
      stock
      slots_start_date
      max_slot_offset
      user_can_cancel
      additional_info
      user_can_reschedule
      enable_customizations_quantity_factor
      show_customizations_price
      allows_past_slots
      sort_index
      slots_recurrence
      booking_type
      slots
      Branch {
        id
        photos
        address
        name
        colors
        latitude
        longitude
        booking_slots
        BranchTargeting {
          id
          visibility
          status
          pet_type
        }
      }
    }
  }
`;

export const GetReducedBranchProducts = gql`
  query getBranchProducts(
    $name: [String]
    $product_search: [String]
    $id: [String]
    $type: [String]
    $status: [String]
    $requisite_queries: [String]!
    $alternative_queries: [String]!
    $limit: Int
    $offset: Int
  ) {
    getBranchProducts(
      GetBranchProductsInput: { name: $name, product_search: $product_search, id: $id, type: $type, status: $status, requisite_queries: $requisite_queries, alternative_queries: $alternative_queries }
      limit: $limit
      offset: $offset
    ) {
      id
      name
      short_description
    }
  }
`;

export const GetSearchBranches = gql`
  query searchBranches($name: String!, $categoryId: String, $visibility: String) {
    searchBranches(SearchBranchesInput: { name: $name, categoryId: $categoryId, visibility: $visibility }) {
      id
      photos
      address
      name
      colors
      latitude
      longitude
      email
      contact_number
      BranchTargeting {
        id
        visibility
        status
        pet_type
      }
    }
  }
`;

export const GetBranchPetRecordItems = gql`
  query branchPetRecordItemGet($PetRecordId: String!, $type: BranchPetRecordItemtypeEnumType) {
    branchPetRecordItemGet(where: { PetRecordId: $PetRecordId, type: $type }) {
      id
      BranchId
      type
      days
    }
  }
`;

export const GetBranchMessageTemplates = gql`
  query branchMessageTemplateGet($offset: Int, $limit: Int) {
    branchMessageTemplateGet(offset: $offset, limit: $limit) {
      id
      message
      html_message
      name
      type
      Products {
        id
        name
      }
      Branch {
        id
        name
      }
    }
  }
`;

export const GetAllBranchMessageTemplates = gql`
  query branchMessageTemplateGet {
    branchMessageTemplateGet(offset: 0, limit: 1000, where: {}) {
      id
      message
      html_message
      name
      type
    }
  }
`;

export const AddBranchMessageTemplate = gql`
  mutation addBranchMessageTemplate($name: String!, $message: String!, $html_message: String!, $type: String!, $ProductId: [String]) {
    addBranchMessageTemplate(AddBranchMessageTemplateInput: { name: $name, message: $message, html_message: $html_message, type: $type, ProductId: $ProductId }) {
      id
      message
      html_message
      name
      type
      Products {
        id
        name
      }
      Branch {
        id
        name
      }
    }
  }
`;

export const EditBranchMessageTemplate = gql`
  mutation editBranchMessageTemplate($id: String!, $name: String, $message: String, $html_message: String, $type: String, $ProductId: [String]) {
    editBranchMessageTemplate(EditBranchMessageTemplateInput: { id: $id, name: $name, message: $message, html_message: $html_message, type: $type, ProductId: $ProductId }) {
      id
      message
      html_message
      name
      type
      Products {
        id
        name
      }
      Branch {
        id
        name
      }
    }
  }
`;

export const DeleteBranchMessageTemplate = gql`
  mutation branchMessageTemplateDelete($id: String!) {
    branchMessageTemplateDelete(id: $id)
  }
`;

export const GetBranchOrderInvoices = gql`
  ${BRANCH_ORDER_INVOICE_FRAGMENT}
  query getBranchOrderInvoices(
    $offset: Int
    $limit: Int
    $AppUserId: [String]
    $billed_on_from: String
    $billed_on_to: String
    $invoice_date_from: String
    $invoice_date_to: String
    $OrderInvoiceId: [String]
    $status: [String]
    $unbilled: Boolean
    $BranchAppUserTagId: [String]
  ) {
    getBranchOrderInvoices(
      offset: $offset
      limit: $limit
      GetBranchOrderInvoicesInput: {
        AppUserId: $AppUserId
        billed_on_from: $billed_on_from
        billed_on_to: $billed_on_to
        invoice_date_from: $invoice_date_from
        invoice_date_to: $invoice_date_to
        OrderInvoiceId: $OrderInvoiceId
        status: $status
        unbilled: $unbilled
        BranchAppUserTagId: $BranchAppUserTagId
      }
    ) {
      ...BranchOrderInvoiceFragment
    }
  }
`;

export const RetryOrderInvoice = gql`
  ${BRANCH_ORDER_INVOICE_FRAGMENT}
  mutation retryOrderInvoice($OrderInvoiceId: [String]!) {
    retryOrderInvoice(RetryOrderInvoiceInput: { OrderInvoiceId: $OrderInvoiceId }) {
      ...BranchOrderInvoiceFragment
    }
  }
`;

export const SendOrderInvoiceEmail = gql`
  ${BRANCH_ORDER_INVOICE_FRAGMENT}
  mutation sendOrderInvoiceEmail($OrderInvoiceId: String!) {
    sendOrderInvoiceEmail(SendOrderInvoiceEmailInput: { OrderInvoiceId: $OrderInvoiceId }) {
      ...BranchOrderInvoiceFragment
    }
  }
`;

export const UpdateOrderInvoiceStatus = gql`
  ${BRANCH_ORDER_INVOICE_FRAGMENT}
  mutation updateOrderInvoiceStatus($OrderInvoiceId: String!, $status: String!) {
    updateOrderInvoiceStatus(UpdateOrderInvoiceStatusInput: { OrderInvoiceId: $OrderInvoiceId, status: $status }) {
      ...BranchOrderInvoiceFragment
    }
  }
`;

export const UpdateOrderInvoiceAmount = gql`
  ${BRANCH_ORDER_INVOICE_FRAGMENT}
  mutation updateOrderInvoiceAmount($OrderInvoiceId: String!, $amount: String!) {
    updateOrderInvoiceAmount(UpdateOrderInvoiceAmountInput: { OrderInvoiceId: $OrderInvoiceId, amount: $amount }) {
      ...BranchOrderInvoiceFragment
    }
  }
`;

export const GetBranchPetRecordTags = gql`
  ${BRANCH_PET_RECORD_TAG_FRAGMENT}
  query getBranchPetRecordTags($offset: Int, $limit: Int, $id: [String], $BranchTagCategoryId: [String], $quick_action: Boolean, $visibility: [String]) {
    getBranchPetRecordTags(offset: $offset, limit: $limit, GetBranchPetRecordTagsInput: { id: $id, BranchTagCategoryId: $BranchTagCategoryId, quick_action: $quick_action, visibility: $visibility }) {
      ...BranchPetRecordTagFragment
    }
  }
`;

export const GetBranchAppointmentTags = gql`
  ${BRANCH_APPOINTMENT_TAG_FRAGMENT}
  query getBranchAppointmentTags(
    $offset: Int
    $limit: Int
    $appointmentsCount_timestamp_from: String
    $appointmentsCount_timestamp_to: String
    $id: [String]
    $BranchTagCategoryId: [String]
    $quick_action: Boolean
    $visibility: [String]
    $appointmentsCount_ProductId: [String]
    $appointmentsCount_booking_type: [String]
  ) {
    getBranchAppointmentTags(
      offset: $offset
      limit: $limit
      GetBranchAppointmentTagsInput: {
        id: $id
        appointmentsCount_timestamp_from: $appointmentsCount_timestamp_from
        appointmentsCount_timestamp_to: $appointmentsCount_timestamp_to
        BranchTagCategoryId: $BranchTagCategoryId
        quick_action: $quick_action
        visibility: $visibility
        appointmentsCount_ProductId: $appointmentsCount_ProductId
        appointmentsCount_booking_type: $appointmentsCount_booking_type
      }
    ) {
      ...BranchAppointmentTagFragment
    }
  }
`;

export const GetBranchAppUserTags = gql`
  ${BRANCH_APP_USER_TAG_FRAGMENT}
  query getBranchAppUserTags($offset: Int, $limit: Int, $id: [String], $BranchTagCategoryId: [String], $quick_action: Boolean, $visibility: [String]) {
    getBranchAppUserTags(offset: $offset, limit: $limit, GetBranchAppUserTagsInput: { id: $id, BranchTagCategoryId: $BranchTagCategoryId, quick_action: $quick_action, visibility: $visibility }) {
      ...BranchAppUserTagFragment
    }
  }
`;

export const AddBranchPetRecordTag = gql`
  ${BRANCH_PET_RECORD_TAG_FRAGMENT}
  mutation branchPetRecordTagAdd($name: String!, $automations: SequelizeJSON, $quick_action: Boolean, $color: String, $capacity: Int, $reset_at: SequelizeJSON, $visibility: SequelizeJSON) {
    branchPetRecordTagAdd(
      BranchPetRecordTag: { name: $name, automations: $automations, quick_action: $quick_action, color: $color, capacity: $capacity, reset_at: $reset_at, visibility: $visibility }
    ) {
      ...BranchPetRecordTagFragment
    }
  }
`;

export const AddBranchAppointmentTag = gql`
  ${BRANCH_APPOINTMENT_TAG_FRAGMENT}
  mutation branchAppointmentTagAdd($name: String!, $automations: SequelizeJSON, $quick_action: Boolean, $color: String, $capacity: Int, $reset_at: SequelizeJSON, $visibility: SequelizeJSON) {
    branchAppointmentTagAdd(
      BranchAppointmentTag: { name: $name, automations: $automations, quick_action: $quick_action, color: $color, capacity: $capacity, reset_at: $reset_at, visibility: $visibility }
    ) {
      ...BranchAppointmentTagFragment
    }
  }
`;

export const AddBranchAppUserTag = gql`
  ${BRANCH_APP_USER_TAG_FRAGMENT}
  mutation branchAppUserTagAdd($name: String!, $automations: SequelizeJSON, $quick_action: Boolean, $color: String, $capacity: Int, $reset_at: SequelizeJSON, $visibility: SequelizeJSON) {
    branchAppUserTagAdd(BranchAppUserTag: { name: $name, automations: $automations, quick_action: $quick_action, color: $color, capacity: $capacity, reset_at: $reset_at, visibility: $visibility }) {
      ...BranchAppUserTagFragment
    }
  }
`;

export const EditBranchPetRecordTag = gql`
  ${BRANCH_PET_RECORD_TAG_FRAGMENT}
  mutation branchPetRecordTagEdit(
    $id: String!
    $name: String!
    $automations: SequelizeJSON
    $quick_action: Boolean
    $color: String
    $capacity: Int
    $reset_at: SequelizeJSON
    $visibility: SequelizeJSON
  ) {
    branchPetRecordTagEdit(
      BranchPetRecordTag: { id: $id, name: $name, automations: $automations, quick_action: $quick_action, color: $color, capacity: $capacity, reset_at: $reset_at, visibility: $visibility }
    ) {
      ...BranchPetRecordTagFragment
    }
  }
`;

export const EditBranchAppointmentTag = gql`
  ${BRANCH_APPOINTMENT_TAG_FRAGMENT}
  mutation branchAppointmentTagEdit(
    $id: String!
    $name: String!
    $automations: SequelizeJSON
    $quick_action: Boolean
    $color: String
    $capacity: Int
    $reset_at: SequelizeJSON
    $visibility: SequelizeJSON
  ) {
    branchAppointmentTagEdit(
      BranchAppointmentTag: { id: $id, name: $name, automations: $automations, quick_action: $quick_action, color: $color, capacity: $capacity, reset_at: $reset_at, visibility: $visibility }
    ) {
      ...BranchAppointmentTagFragment
    }
  }
`;

export const EditBranchAppUserTag = gql`
  ${BRANCH_APP_USER_TAG_FRAGMENT}
  mutation branchAppUserTagEdit(
    $id: String!
    $name: String!
    $automations: SequelizeJSON
    $quick_action: Boolean
    $color: String
    $capacity: Int
    $reset_at: SequelizeJSON
    $visibility: SequelizeJSON
  ) {
    branchAppUserTagEdit(
      BranchAppUserTag: { id: $id, name: $name, automations: $automations, quick_action: $quick_action, color: $color, capacity: $capacity, reset_at: $reset_at, visibility: $visibility }
    ) {
      ...BranchAppUserTagFragment
    }
  }
`;

export const DeleteBranchPetRecordTag = gql`
  mutation branchPetRecordTagDelete($id: String!) {
    branchPetRecordTagDelete(id: $id)
  }
`;

export const DeleteBranchAppointmentTag = gql`
  mutation branchAppointmentTagDelete($id: String!) {
    branchAppointmentTagDelete(id: $id)
  }
`;

export const DeleteBranchAppUserTag = gql`
  mutation branchAppUserTagDelete($id: String!) {
    branchAppUserTagDelete(id: $id)
  }
`;

export const AddBranchPetRecordItemTag = gql`
  ${PET_FRAGMENT}
  mutation addBranchPetRecordTagItems($PetRecordId: [String]!, $BranchPetRecordTagId: [String]!) {
    addBranchPetRecordTagItems(AddBranchPetRecordTagItemsInput: { PetRecordId: $PetRecordId, BranchPetRecordTagId: $BranchPetRecordTagId }) {
      id
      PetRecord {
        id
        Pet {
          ...PetFragment
        }
      }
    }
  }
`;

export const AddBranchAppointmentItemTag = gql`
  ${BOOKINGS_APPOINTMENTS}
  mutation addBranchAppointmentTagItems($AppointmentId: [String]!, $BranchAppointmentTagId: [String]!) {
    addBranchAppointmentTagItems(AddBranchAppointmentTagItemsInput: { AppointmentId: $AppointmentId, BranchAppointmentTagId: $BranchAppointmentTagId }) {
      id
      Appointment {
        ...AppointmentFragment
      }
    }
  }
`;

export const AddBranchAppUserItemTag = gql`
  ${APP_USER_FRAGMENT}
  mutation addBranchAppUserTagItems($AppUserId: [String]!, $BranchAppUserTagId: [String]!) {
    addBranchAppUserTagItems(AddBranchAppUserTagItemsInput: { AppUserId: $AppUserId, BranchAppUserTagId: $BranchAppUserTagId }) {
      id
      AppUser {
        ...AppUserFragment
      }
    }
  }
`;

export const ReplaceBranchPetRecordItemTag = gql`
  mutation replaceBranchPetRecordTagItems($petRecordId: [String]!, $BranchPetRecordTagId: [String]!) {
    replaceBranchPetRecordTagItems(ReplaceBranchPetRecordTagItemsInput: { PetRecordId: $petRecordId, BranchPetRecordTagId: $BranchPetRecordTagId }) {
      id
    }
  }
`;

export const ReplaceBranchAppointmentItemTag = gql`
  mutation replaceBranchAppointmentTagItems($appointmentId: [String]!, $BranchAppointmentTagId: [String]!) {
    replaceBranchAppointmentTagItems(ReplaceBranchAppointmentTagItemsInput: { AppointmentId: $appointmentId, BranchAppointmentTagId: $BranchAppointmentTagId }) {
      id
    }
  }
`;

export const ReplaceBranchAppUserItemTag = gql`
  mutation replaceBranchAppUserTagItems($AppUserId: [String]!, $BranchAppUserTagId: [String]!) {
    replaceBranchAppUserTagItems(ReplaceBranchAppUserTagItemsInput: { AppUserId: $AppUserId, BranchAppUserTagId: $BranchAppUserTagId }) {
      id
    }
  }
`;

export const GetBranchPetRecordItemTag = gql`
  query branchPetRecordTagItemGet($petRecordId: String!) {
    branchPetRecordTagItemGet(PetRecordId: $petRecordId) {
      id
      name
      Branch {
        id
        name
      }
    }
  }
`;

export const GetBranchAppointmentItemTag = gql`
  query branchAppointmentTagItemGet($appointmentId: String!) {
    branchAppointmentTagItemGet(AppointmentId: $appointmentId) {
      id
      name
      Branch {
        id
        name
      }
    }
  }
`;

export const GetBranchAppUserItemTag = gql`
  query branchAppUserTagItemGet($AppUserId: String!) {
    branchAppUserTagItemGet(AppUserId: $AppUserId) {
      id
      name
      Branch {
        id
        name
      }
    }
  }
`;

export const GetBranchTags = gql`
  ${BRANCH_PET_RECORD_TAG_FRAGMENT}
  ${BRANCH_APPOINTMENT_TAG_FRAGMENT}
  ${BRANCH_APP_USER_TAG_FRAGMENT}
  query getBranchTags($offset: Int, $limit: Int, $where: SequelizeJSON) {
    getBranchTags(offset: $offset, limit: $limit, where: $where) {
      branchPetRecordTags {
        ...BranchPetRecordTagFragment
      }
      branchAppointmentTags {
        ...BranchAppointmentTagFragment
      }
      branchAppUserTags {
        ...BranchAppUserTagFragment
      }
    }
  }
`;

export const AddBranchTagCategoryItems = gql`
  mutation addBranchTagCategoryItems($BranchTagCategoryId: [String]!, $BranchPetRecordTagId: [String], $BranchAppointmentTagId: [String], $BranchAppUserTagId: [String]) {
    addBranchTagCategoryItems(
      AddBranchTagCategoryItemsInput: {
        BranchTagCategoryId: $BranchTagCategoryId
        BranchPetRecordTagId: $BranchPetRecordTagId
        BranchAppointmentTagId: $BranchAppointmentTagId
        BranchAppUserTagId: $BranchAppUserTagId
      }
    ) {
      id
    }
  }
`;

export const ReplaceBranchTagCategoryItems = gql`
  mutation replaceBranchTagCategoryItems($BranchTagCategoryId: [String]!, $BranchPetRecordTagId: [String], $BranchAppointmentTagId: [String], $BranchAppUserTagId: [String]) {
    replaceBranchTagCategoryItems(
      ReplaceBranchTagCategoryItemsInput: {
        BranchTagCategoryId: $BranchTagCategoryId
        BranchPetRecordTagId: $BranchPetRecordTagId
        BranchAppointmentTagId: $BranchAppointmentTagId
        BranchAppUserTagId: $BranchAppUserTagId
      }
    ) {
      id
    }
  }
`;

export const CheckBranchAppointmentAvailability = gql`
  query checkBranchAppointmentAvailability($branchId: String!, $productId: String, $slots_length: Int, $start_date: String, $selected_dates: [String]) {
    checkBranchAppointmentAvailability(
      CheckBranchAppointmentAvailabilityInput: { branchId: $branchId, productId: $productId, slots_length: $slots_length, start_date: $start_date, selected_dates: $selected_dates }
    ) {
      slots
      dates
    }
  }
`;

export const GetBranchSchedulesSlots = gql`
  query getBranchSchedulesSlots($branchScheduleId: [String], $slots_length: Int, $start_date: String, $selected_dates: [String], $booking_type: [String]) {
    getBranchSchedulesSlots(
      GetBranchSchedulesSlotsInput: { branchScheduleId: $branchScheduleId, slots_length: $slots_length, start_date: $start_date, selected_dates: $selected_dates, booking_type: $booking_type }
    ) {
      branchSchedules {
        BranchSchedule {
          id
          name
          slots
          booking_type
        }
        availability {
          slots
        }
      }
      availability {
        slots
      }
    }
  }
`;

export const GetBranchTagCategories = gql`
  query getBranchTagCategories($where: SequelizeJSON, $BranchAppointmentTagId: [String], $BranchPetRecordTagId: [String], $BranchAppUserTagId: [String]) {
    getBranchTagCategories(
      offset: 0
      limit: 1000
      where: $where
      GetBranchTagCategoriesInput: { BranchAppointmentTagId: $BranchAppointmentTagId, BranchPetRecordTagId: $BranchPetRecordTagId, BranchAppUserTagId: $BranchAppUserTagId }
    ) {
      id
      name
    }
  }
`;

export const GetBranchTagCategoriesWithTags = gql`
  query getBranchTagCategories($where: SequelizeJSON, $BranchAppointmentTagId: [String], $BranchPetRecordTagId: [String], $BranchAppUserTagId: [String]) {
    getBranchTagCategories(
      offset: 0
      limit: 1000
      where: $where
      GetBranchTagCategoriesInput: { BranchAppointmentTagId: $BranchAppointmentTagId, BranchPetRecordTagId: $BranchPetRecordTagId, BranchAppUserTagId: $BranchAppUserTagId }
    ) {
      id
      name
      BranchAppointmentTags {
        id
      }
      BranchPetRecordTags {
        id
      }
      BranchAppUserTags {
        id
      }
    }
  }
`;

export const AddBranchTagCategory = gql`
  mutation branchTagCategoryAdd($name: String!) {
    branchTagCategoryAdd(BranchTagCategory: { name: $name }) {
      id
      name
    }
  }
`;

export const GetBranchForms = gql`
  ${BRANCH_FORM_FRAGMENT}
  query formGet($offset: Int, $limit: Int, $where: SequelizeJSON) {
    formGet(offset: $offset, limit: $limit, where: $where) {
      ...BranchFormFragment
    }
  }
`;

export const AddBranchForm = gql`
  ${BRANCH_FORM_FRAGMENT}
  mutation addBranchForm($name: String!, $type: String!, $level: String, $ProductId: [String]) {
    addBranchForm(AddBranchFormInput: { name: $name, type: $type, level: $level, ProductId: $ProductId }) {
      ...BranchFormFragment
    }
  }
`;

export const EditBranchForm = gql`
  ${BRANCH_FORM_FRAGMENT}
  mutation editBranchForm($id: String!, $name: String!, $type: String!, $level: String, $ProductId: [String]) {
    editBranchForm(EditBranchFormInput: { id: $id, name: $name, type: $type, level: $level, ProductId: $ProductId }) {
      ...BranchFormFragment
    }
  }
`;

export const ReplaceFormQuestions = gql`
  mutation replaceFormQuestions($FormId: String!, $QuestionId: [String]) {
    replaceFormQuestions(ReplaceFormQuestionsInput: { FormId: $FormId, QuestionId: $QuestionId }) {
      id
    }
  }
`;

export const DeleteBranchForm = gql`
  mutation formDelete($id: String!) {
    formDelete(id: $id)
  }
`;

export const GetQuestionsCategories = gql`
  query questionCategoryGet {
    questionCategoryGet {
      id
      name
      Questions(limit: 1000) {
        id
        title
        type
      }
    }
  }
`;

export const GetQuestionsByCategoryId = gql`
  query getQuestionsByQuestionCategoryId($QuestionCategoryId: [String]!) {
    getQuestionsByQuestionCategoryId(GetQuestionsByQuestionCategoryIdInput: { QuestionCategoryId: $QuestionCategoryId }, offset: 0, limit: 1000) {
      id
      title
      type
    }
  }
`;
/**
 * @deprecated
 * use GetBranchSubmittedForms instead
 */
export const GetBranchFormsByPetRecordId = gql`
  ${BRANCH_FORM_FRAGMENT}
  ${SUBMITTED_FORM_FRAGMENT}
  query getBranchFormsByPetRecordId($offset: Int, $limit: Int, $PetRecordId: [String]!, $type: [String], $OrderId: [String]) {
    getBranchFormsByPetRecordId(GetBranchFormsByPetRecordIdInput: { PetRecordId: $PetRecordId, type: $type, OrderId: $OrderId }, offset: $offset, limit: $limit) {
      answeredForms {
        ...SubmittedFormFragment
      }
      nonAnsweredForms {
        ...BranchFormFragment
      }
    }
  }
`;

export const GetBranchSubmittedForms = gql`
  ${SUBMITTED_FORM_FRAGMENT}
  query getBranchSubmittedForms($offset: Int, $limit: Int, $PetRecordId: [String], $AppUserId: [String], $id: [String], $name: [String]) {
    getBranchSubmittedForms(GetBranchSubmittedFormsInput: { PetRecordId: $PetRecordId, AppUserId: $AppUserId, id: $id, name: $name }, offset: $offset, limit: $limit) {
      ...SubmittedFormFragment
    }
  }
`;

export const GetBranchSubmittedFormsViews = gql`
  ${SUBMITTED_FORM_FRAGMENT}
  query getBranchSubmittedFormsViews($offset: Int, $limit: Int, $PetRecordId: [String], $AppUserId: [String], $id: [String], $name: [String]) {
    getBranchSubmittedFormsViews(GetBranchSubmittedFormsViewsInput: { PetRecordId: $PetRecordId, AppUserId: $AppUserId, id: $id, name: $name }, offset: $offset, limit: $limit) {
      count
      views {
        ...SubmittedFormFragment
      }
    }
  }
`;

export const GetOrderInvoiceOrders = gql`
  query getOrderInvoiceOrders($OrderInvoiceId: String!) {
    getOrderInvoiceOrders(GetOrderInvoiceOrdersInput: { OrderInvoiceId: $OrderInvoiceId }) {
      id
      total
      credit_used
      number
      createdAt
      status
      billed_on
      AppUser {
        id
        name
      }
      OrderBills(limit: 1) {
        id
        status
        billed_on
        OrderInvoice {
          id
          Order {
            id
            status
          }
        }
      }
      OrderItems {
        id
        item
        Appointments {
          id
          timestamp
          PetRecord {
            id
            Pet {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GetPharmaItems = gql`
  query getPharmaItems($offset: Int, $limit: Int, $where: SequelizeJSON, $description: String) {
    getPharmaItems(offset: $offset, limit: $limit, where: $where, GetPharmaItemsInput: { description: $description }) {
      id
      description
      cost
      supplier
    }
  }
`;

export const BatchCreateProductsFromPharmaItems = gql`
  ${PRODUCT_FRAGMENT}
  mutation batchCreateProductsFromPharmaItems($batchCreateProductsFromPharmaItemsInput: BatchCreateProductsFromPharmaItemsInput!) {
    batchCreateProductsFromPharmaItems(BatchCreateProductsFromPharmaItemsInput: $batchCreateProductsFromPharmaItemsInput) {
      ...ProductFragment
    }
  }
`;

export const AddBranchNote = gql`
  ${NOTE_FRAGMENT}
  mutation addBranchNote(
    $body: SequelizeJSON!
    $timestamp: Date!
    $AppointmentId: String
    $MedCondId: String
    $GroomingReportId: String
    $TrainingReportId: String
    $WalkingReportId: String
    $SittingReportId: String
    $DaycareReportId: String
    $OrderId: String
    $PetRecordId: String
    $AppUserId: String
  ) {
    addBranchNote(
      Note: {
        body: $body
        timestamp: $timestamp
        AppointmentId: $AppointmentId
        MedCondId: $MedCondId
        GroomingReportId: $GroomingReportId
        TrainingReportId: $TrainingReportId
        WalkingReportId: $WalkingReportId
        SittingReportId: $SittingReportId
        DaycareReportId: $DaycareReportId
        OrderId: $OrderId
        PetRecordId: $PetRecordId
        AppUserId: $AppUserId
      }
    ) {
      ...NoteFragment
    }
  }
`;

export const GetBranchNotes = gql`
  ${NOTE_FRAGMENT}
  query getBranchNotes(
    $offset: Int
    $limit: Int
    $where: SequelizeJSON
    $MedCondId: [String]
    $GroomingReportId: [String]
    $TrainingReportId: [String]
    $WalkingReportId: [String]
    $SittingReportId: [String]
    $DaycareReportId: [String]
    $OrderId: [String]
    $AppUserId: [String]
    $PetRecordId: [String]
  ) {
    getBranchNotes(
      offset: $offset
      limit: $limit
      where: $where
      GetBranchNotesInput: {
        MedCondId: $MedCondId
        GroomingReportId: $GroomingReportId
        TrainingReportId: $TrainingReportId
        WalkingReportId: $WalkingReportId
        SittingReportId: $SittingReportId
        DaycareReportId: $DaycareReportId
        OrderId: $OrderId
        AppUserId: $AppUserId
        PetRecordId: $PetRecordId
      }
    ) {
      ...NoteFragment
      MedCondId
      GroomingReportId
      TrainingReportId
      WalkingReportId
      SittingReportId
      DaycareReportId
      OrderId
      AppUserId
      PetRecordId
    }
  }
`;

export const GetBranchNotesByMedCondId = gql`
  ${NOTE_FRAGMENT}
  query getBranchNotesByMedCondId($offset: Int, $limit: Int, $MedCondId: [String!]!) {
    getBranchNotesByMedCondId(GetBranchNotesByMedCondIdInput: { MedCondId: $MedCondId }, offset: $offset, limit: $limit, order: "createdAt") {
      ...NoteFragment
    }
  }
`;

export const GetBranchNotesByGroomingReportId = gql`
  ${NOTE_FRAGMENT}
  query getBranchNotesByGroomingReportId($offset: Int, $limit: Int, $GroomingReportId: [String!]!) {
    getBranchNotesByGroomingReportId(GetBranchNotesByGroomingReportIdInput: { GroomingReportId: $GroomingReportId }, offset: $offset, limit: $limit, order: "createdAt") {
      ...NoteFragment
    }
  }
`;

export const GetBranchNotesByTrainingReportId = gql`
  ${NOTE_FRAGMENT}
  query getBranchNotesByTrainingReportId($offset: Int, $limit: Int, $TrainingReportId: [String!]!) {
    getBranchNotesByTrainingReportId(GetBranchNotesByTrainingReportIdInput: { TrainingReportId: $TrainingReportId }, offset: $offset, limit: $limit, order: "createdAt") {
      ...NoteFragment
    }
  }
`;

export const GetBranchNotesByWalkingReportId = gql`
  ${NOTE_FRAGMENT}
  query getBranchNotesByWalkingReportId($offset: Int, $limit: Int, $WalkingReportId: [String!]!) {
    getBranchNotesByWalkingReportId(GetBranchNotesByWalkingReportIdInput: { WalkingReportId: $WalkingReportId }, offset: $offset, limit: $limit, order: "createdAt") {
      ...NoteFragment
    }
  }
`;

export const GetBranchNotesBySittingReportId = gql`
  ${NOTE_FRAGMENT}
  query getBranchNotesBySittingReportId($offset: Int, $limit: Int, $SittingReportId: [String!]!) {
    getBranchNotesBySittingReportId(GetBranchNotesBySittingReportIdInput: { SittingReportId: $SittingReportId }, offset: $offset, limit: $limit, order: "createdAt") {
      ...NoteFragment
    }
  }
`;

export const GetBranchNotesByDaycareReportId = gql`
  ${NOTE_FRAGMENT}
  query getBranchNotesByDaycareReportId($offset: Int, $limit: Int, $DaycareReportId: [String!]!) {
    getBranchNotesByDaycareReportId(GetBranchNotesByDaycareReportIdInput: { DaycareReportId: $DaycareReportId }, offset: $offset, limit: $limit, order: "createdAt") {
      ...NoteFragment
    }
  }
`;

export const GetBranchNotesByOrderId = gql`
  ${NOTE_FRAGMENT}
  query getBranchNotesByOrderId($offset: Int, $limit: Int, $OrderId: [String!]!) {
    getBranchNotesByOrderId(GetBranchNotesByOrderIdInput: { OrderId: $OrderId }, offset: $offset, limit: $limit, order: "createdAt") {
      ...NoteFragment
    }
  }
`;

export const GetBranchNotesByAppUserId = gql`
  ${NOTE_FRAGMENT}
  query getBranchNotesByAppUserId($offset: Int, $limit: Int, $AppUserId: [String!]!) {
    getBranchNotesByAppUserId(GetBranchNotesByAppUserIdInput: { AppUserId: $AppUserId }, offset: $offset, limit: $limit, order: "createdAt") {
      ...NoteFragment
    }
  }
`;

export const GetBranchNotesByPetRecordId = gql`
  ${NOTE_FRAGMENT}
  query getBranchNotesByPetRecordId($offset: Int, $limit: Int, $PetRecordId: [String!]!) {
    getBranchNotesByPetRecordId(GetBranchNotesByPetRecordIdInput: { PetRecordId: $PetRecordId }, offset: $offset, limit: $limit, order: "createdAt") {
      ...NoteFragment
    }
  }
`;

export const GetBranchSummaryCounts = gql`
  query getBranchSummaryCounts($counts: [GetBranchSummaryCountsInputCountInput]!, $timestamp_from: String, $timestamp_to: String) {
    getBranchSummaryCounts(GetBranchSummaryCountsInput: { counts: $counts, timestamp_from: $timestamp_from, timestamp_to: $timestamp_to }) {
      counts {
        name
        data
      }
    }
  }
`;

export const UpdateAppUsersOrdersAmountFromProducts = gql`
  mutation updateAppUsersOrdersAmountFromProducts(
    $timestamp_from: String
    $timestamp_to: String
    $reason: String
    $AppUsersOrdersAmountFromProducts: [UpdateAppUsersOrdersAmountFromProductsInputAppUserOrdersAmountFromProductsInput]!
  ) {
    updateAppUsersOrdersAmountFromProducts(
      UpdateAppUsersOrdersAmountFromProductsInput: {
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        reason: $reason
        AppUsersOrdersAmountFromProducts: $AppUsersOrdersAmountFromProducts
      }
    ) {
      id
    }
  }
`;

export const GetUpdateAppUsersOrdersAmountFromProductsSummary = gql`
  query getUpdateAppUsersOrdersAmountFromProductsSummary(
    $timestamp_from: String
    $timestamp_to: String
    $reason: String
    $AppUsersOrdersAmountFromProducts: [UpdateAppUsersOrdersAmountFromProductsInputAppUserOrdersAmountFromProductsInput]!
  ) {
    getUpdateAppUsersOrdersAmountFromProductsSummary(
      GetUpdateAppUsersOrdersAmountFromProductsSummaryInput: {
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        reason: $reason
        AppUsersOrdersAmountFromProducts: $AppUsersOrdersAmountFromProducts
      }
    ) {
      ordersToUpdate
      appUsersToUpdate
    }
  }
`;

export const GenerateBranchUniqueVoucherCode = gql`
  query generateBranchUniqueVoucherCode($codesToAvoid: [String]) {
    generateBranchUniqueVoucherCode(GenerateBranchUniqueVoucherCodeInput: { codesToAvoid: $codesToAvoid }) {
      code
    }
  }
`;

export const EditBranchOrderItems = gql`
  mutation editBranchOrderItems($id: [String]!, $item_duration: String) {
    editBranchOrderItems(EditBranchOrderItemsInput: { id: $id, item_duration: $item_duration }) {
      id
      item
    }
  }
`;

export const GetAllCountries = gql`
  query countryGet {
    countryGet(limit: 1000) {
      id
      name
    }
  }
`;

export const GetBranchPayouts = gql`
  ${BRANCH_PAYOUT_FRAGMENT}
  query getBranchPayouts($starting_after: String, $limit: Int, $offset: Int) {
    getBranchPayouts(GetBranchPayoutsInput: { starting_after: $starting_after }, limit: $limit, offset: $offset) {
      ...BranchPayoutFragment
    }
  }
`;

export const GetBranchPayout = gql`
  ${BRANCH_PAYOUT_FRAGMENT}
  query getBranchPayouts($id: String) {
    getBranchPayouts(GetBranchPayoutsInput: { id: $id }) {
      ...BranchPayoutFragment
    }
  }
`;

export const GetBranchBalanceTransactions = gql`
  ${BRANCH_BALANCE_TRANSACTION_FRAGMENT}
  query getBranchBalanceTransactions($limit: Int, $offset: Int, $starting_after: String, $id: String, $payout_id: String) {
    getBranchBalanceTransactions(GetBranchBalanceTransactionsInput: { starting_after: $starting_after, id: $id, payout_id: $payout_id }, limit: $limit, offset: $offset) {
      ...BranchBalanceTransactionFragment
    }
  }
`;

export const GetBranchSchedules = gql`
  ${BRANCH_SCHEDULE_FRAGMENT}
  query getBranchSchedules($limit: Int, $offset: Int, $id: [String], $ProductId: [String], $booking_type: [String], $search: [String]) {
    getBranchSchedules(limit: $limit, offset: $offset, GetBranchSchedulesInput: { id: $id, ProductId: $ProductId, booking_type: $booking_type, search: $search }) {
      ...BranchScheduleFragment
    }
  }
`;
export const UpdateOrderSubscriptionRepeatInvoiceStatus = gql`
  ${ORDER_SUBSCRIPTION_REPEAT_FRAGMENT}
  mutation updateOrderSubscriptionRepeatInvoiceStatus($id: String!, $invoice_status: String!) {
    updateOrderSubscriptionRepeatInvoiceStatus(UpdateOrderSubscriptionRepeatInvoiceStatusInput: { id: $id, invoice_status: $invoice_status }) {
      ...OrderSubscriptionRepeatFragment
    }
  }
`;

export const AddBranchSchedule = gql`
  ${BRANCH_SCHEDULE_FRAGMENT}
  mutation addBranchSchedule($booking_type: String!, $slots_start_date: String, $slots_recurrence: Int, $slots: [SequelizeJSON]!, $name: String!) {
    addBranchSchedule(AddBranchScheduleInput: { booking_type: $booking_type, slots_start_date: $slots_start_date, slots_recurrence: $slots_recurrence, slots: $slots, name: $name }) {
      ...BranchScheduleFragment
    }
  }
`;
export const RetryOrderSubscriptionRepeatInvoice = gql`
  ${ORDER_SUBSCRIPTION_REPEAT_FRAGMENT}
  mutation retryOrderSubscriptionRepeatInvoice($id: String!) {
    retryOrderSubscriptionRepeatInvoice(RetryOrderSubscriptionRepeatInvoiceInput: { id: $id }) {
      ...OrderSubscriptionRepeatFragment
    }
  }
`;

export const GetSlots = gql`
  query getSlots($branchId: String!, $productId: String, $slots_length: Int, $start_date: String, $selected_dates: [String]) {
    getSlots(GetSlotsInput: { branchId: $branchId, productId: $productId, slots_length: $slots_length, start_date: $start_date, selected_dates: $selected_dates }) {
      slots
    }
  }
`;

export const GetPetRecordMeals = gql`
  query getBranchPetProfile($id: String!) {
    getBranchPetProfile(GetBranchPetProfileInput: { id: $id }) {
      id
      name
      type
      birthdate
      gender
      Breed {
        id
        name
      }
      PetRecord {
        id
        neutred
        measurements
        Meals {
          id
          time
          quantity
          unit
          name
          BranchCategoryId
        }
        BranchCategories {
          id
          name
        }
      }
    }
  }
`;

export const EditBranchPetRecordMeals = gql`
  mutation editBranchPetRecordMeals($meals: [SequelizeJSON!]!, $categories: [String]!, $weight: String) {
    editBranchPetRecordMeals(EditBranchPetRecordMealsInput: { meals: $meals, categories: $categories, weight: $weight }) {
      id
      time
      quantity
      unit
      name
      BranchCategoryId
    }
  }
`;

export const GetPetBehaviors = gql`
  ${PET_BEHAVIOR_FRAGMENT}
  query getPetBehaviors($PetRecordId: [String], $id: [String]) {
    getPetBehaviors(GetPetBehaviorsInput: { PetRecordId: $PetRecordId, id: $id }) {
      ...PetBehaviorFragment
    }
  }
`;

export const EditPetBehavior = gql`
  ${PET_BEHAVIOR_FRAGMENT}
  mutation editPetBehavior(
    $id: String!
    $behavior_around_same_type: String
    $behavior_around_other_pets: String
    $behavior_around_children: String
    $behavior_around_adults: String
    $behavior_negative: String
    $behavior_positive: String
    $sleep_place: String
    $favorite_treats: String
    $feeding_routine: String
    $favorite_activities: String
    $walking_places: String
    $daily_routine: String
    $left_alone_duration: String
    $treatment_routine: String
  ) {
    editPetBehavior(
      EditPetBehaviorInput: {
        id: $id
        behavior_around_same_type: $behavior_around_same_type
        behavior_around_other_pets: $behavior_around_other_pets
        behavior_around_children: $behavior_around_children
        behavior_around_adults: $behavior_around_adults
        behavior_negative: $behavior_negative
        behavior_positive: $behavior_positive
        sleep_place: $sleep_place
        favorite_treats: $favorite_treats
        favorite_activities: $favorite_activities
        feeding_routine: $feeding_routine
        treatment_routine: $treatment_routine
        walking_places: $walking_places
        daily_routine: $daily_routine
        left_alone_duration: $left_alone_duration
      }
    ) {
      ...PetBehaviorFragment
    }
  }
`;

export const TriggerProcessOrderBills = gql`
  mutation triggerProcessOrderBills {
    triggerProcessOrderBills {
      message
    }
  }
`;

export const EditBranchPetRecordConnectionStatus = gql`
  mutation editBranchPetRecordConnectionStatus($id: String!, $status: String!) {
    editBranchPetRecordConnectionStatus(EditBranchPetRecordConnectionStatusInput: { id: $id, status: $status }) {
      id
    }
  }
`;

export const SuggestNewNote = gql`
  mutation suggestNewNote($PetRecordId: String!, $type: String!, $photos: [String]) {
    suggestNewNote(SuggestNewNoteInput: { PetRecordId: $PetRecordId, type: $type, photos: $photos }) {
      text
    }
  }
`;

export const EditBranchAppUserConnectionStatus = gql`
  mutation editBranchAppUserConnectionStatus($id: String!, $status: String!) {
    editBranchAppUserConnectionStatus(EditBranchAppUserConnectionStatusInput: { id: $id, status: $status }) {
      id
    }
  }
`;

export const GetAppUserBranchConnectionStatus = gql`
  query getAppUserBranchConnectionStatus($email: String, $phone_numbers_primary: SequelizeJSON) {
    getAppUserBranchConnectionStatus(GetAppUserBranchConnectionStatusInput: { email: $email, phone_numbers_primary: $phone_numbers_primary }) {
      id
      type
      status
    }
  }
`;

export const GetAppUserBranchCreditsByAppUserId = gql`
  query getAppUserBranchCreditsByAppUserId($AppUserId: [String]!) {
    getAppUserBranchCreditsByAppUserId(GetAppUserBranchCreditsByAppUserIdInput: { AppUserId: $AppUserId }) {
      id
      status
      amount
    }
  }
`;

export const GetAppUserBranchCreditTransactionsByAppUserId = gql`
  query getAppUserBranchCreditTransactionsByAppUserId($AppUserId: [String]!, $offset: Int, $limit: Int) {
    getAppUserBranchCreditTransactionsByAppUserId(GetAppUserBranchCreditTransactionsByAppUserIdInput: { AppUserId: $AppUserId }, offset: $offset, limit: $limit) {
      id
      amount
      reversed
      expiry_date
      action
      Order {
        id
        number
      }
    }
  }
`;

export const UpdateAppUserBranchCredit = gql`
  mutation updateAppUserBranchCredit($AppUserId: String!, $new_amount: String!, $expiry_date: String, $status: String) {
    updateAppUserBranchCredit(UpdateAppUserBranchCreditInput: { AppUserId: $AppUserId, new_amount: $new_amount, expiry_date: $expiry_date, status: $status }) {
      id
      status
      amount
    }
  }
`;

export const GetBranchRoutes = gql`
  query getBranchRoutes($offset: Int, $limit: Int, $BranchAreaId: [String], $id: [String]) {
    getBranchRoutes(offset: $offset, limit: $limit, GetBranchRoutesInput: { BranchAreaId: $BranchAreaId, id: $id }) {
      id
      name
    }
  }
`;

export const GetAllBranchRoutes = gql`
  query getBranchRoutes($BranchAreaId: [String], $id: [String]) {
    getBranchRoutes(offset: 0, limit: 1000, GetBranchRoutesInput: { BranchAreaId: $BranchAreaId, id: $id }) {
      id
      name
    }
  }
`;

export const GetBranchRoute = gql`
  ${BRANCH_ROUTE_FRAGMENT}
  query getBranchRoutes($id: [String], $BranchAreaId: [String]) {
    getBranchRoutes(GetBranchRoutesInput: { id: $id, BranchAreaId: $BranchAreaId }) {
      ...BranchRouteFragment
    }
  }
`;

export const AddBranchRoute = gql`
  ${BRANCH_ROUTE_FRAGMENT}
  mutation addBranchRoute(
    $name: String!
    $start_time: String!
    $job_duration: Int!
    $sort_index: Int!
    $color: String
    $BranchAreaId: String!
    $ProductId: [String]!
    $BranchRoutePetRecords: [BranchRouteInputPetRecordInput]
  ) {
    addBranchRoute(
      AddBranchRouteInput: {
        name: $name
        start_time: $start_time
        job_duration: $job_duration
        sort_index: $sort_index
        color: $color
        BranchAreaId: $BranchAreaId
        BranchRoutePetRecords: $BranchRoutePetRecords
        ProductId: $ProductId
      }
    ) {
      ...BranchRouteFragment
    }
  }
`;

export const EditBranchRoute = gql`
  ${BRANCH_ROUTE_FRAGMENT}
  mutation editBranchRoute(
    $id: String!
    $ProductId: [String]!
    $name: String
    $start_time: String
    $job_duration: Int
    $sort_index: Int
    $color: String
    $BranchAreaId: String
    $BranchRoutePetRecords: [BranchRouteInputPetRecordInput]
  ) {
    editBranchRoute(
      EditBranchRouteInput: {
        id: $id
        name: $name
        start_time: $start_time
        job_duration: $job_duration
        sort_index: $sort_index
        color: $color
        BranchAreaId: $BranchAreaId
        BranchRoutePetRecords: $BranchRoutePetRecords
        ProductId: $ProductId
      }
    ) {
      ...BranchRouteFragment
    }
  }
`;

export const GetBranchAreas = gql`
  query getBranchAreas($offset: Int, $limit: Int, $where: SequelizeJSON) {
    getBranchAreas(offset: $offset, limit: $limit, where: $where) {
      id
      name
      description
    }
  }
`;

export const AddBranchArea = gql`
  mutation addBranchArea($name: String!, $description: String) {
    addBranchArea(AddBranchAreaInput: { name: $name, description: $description }) {
      id
      name
      description
    }
  }
`;

export const GetBranchRouteJobTypes = gql`
  query getBranchRouteJobTypes($offset: Int, $limit: Int, $where: SequelizeJSON) {
    getBranchRouteJobTypes(offset: $offset, limit: $limit, where: $where) {
      id
      name
    }
  }
`;

export const AddBranchRouteJobType = gql`
  mutation addBranchRouteJobType($name: String!) {
    addBranchRouteJobType(AddBranchRouteJobTypeInput: { name: $name }) {
      id
      name
    }
  }
`;

export const GetAllBranchVehicles = gql`
  query getBranchVehicles($BranchRouteId: [String], $BusUserId: [String], $id: [String]) {
    getBranchVehicles(offset: 0, limit: 1000, GetBranchVehiclesInput: { BranchRouteId: $BranchRouteId, BusUserId: $BusUserId, id: $id }) {
      id
      name
      routesCount
      busUserNames
      plate_number
      image
      type
    }
  }
`;

export const GetBranchVehicles = gql`
  query getBranchVehicles($offset: Int, $limit: Int, $BranchRouteId: [String], $BusUserId: [String], $id: [String]) {
    getBranchVehicles(offset: $offset, limit: $limit, GetBranchVehiclesInput: { BranchRouteId: $BranchRouteId, BusUserId: $BusUserId, id: $id }) {
      id
      name
      routesCount
      busUserNames
      plate_number
      image
      type
    }
  }
`;

export const GetBranchVehicle = gql`
  ${BRANCH_VEHICLE_FRAGMENT}
  query getBranchVehicles($id: [String], $BranchRouteId: [String], $BusUserId: [String]) {
    getBranchVehicles(GetBranchVehiclesInput: { id: $id, BranchRouteId: $BranchRouteId, BusUserId: $BusUserId }) {
      ...BranchVehicleFragment
    }
  }
`;

export const AddBranchVehicle = gql`
  ${BRANCH_VEHICLE_FRAGMENT}
  mutation addBranchVehicle(
    $name: String!
    $type: String!
    $status: String!
    $plate_number: String!
    $color: String!
    $image: String!
    $daily_capacity: [SequelizeJSON]!
    $BranchVehicleRoutes: [BranchVehicleInputRouteInput]!
  ) {
    addBranchVehicle(
      AddBranchVehicleInput: {
        name: $name
        type: $type
        status: $status
        plate_number: $plate_number
        color: $color
        image: $image
        daily_capacity: $daily_capacity
        BranchVehicleRoutes: $BranchVehicleRoutes
      }
    ) {
      ...BranchVehicleFragment
    }
  }
`;

export const EditBranchVehicle = gql`
  ${BRANCH_VEHICLE_FRAGMENT}
  mutation editBranchVehicle(
    $id: String!
    $name: String
    $type: String
    $status: String
    $plate_number: String
    $color: String
    $image: String
    $daily_capacity: [SequelizeJSON]
    $BranchVehicleRoutes: [BranchVehicleInputRouteInput]
  ) {
    editBranchVehicle(
      EditBranchVehicleInput: {
        id: $id
        name: $name
        type: $type
        status: $status
        plate_number: $plate_number
        color: $color
        image: $image
        daily_capacity: $daily_capacity
        BranchVehicleRoutes: $BranchVehicleRoutes
      }
    ) {
      ...BranchVehicleFragment
    }
  }
`;

export const GetBranchVehiclesRoutesViews = gql`
  query getBranchVehiclesRoutesViews(
    $timestamp_from: String!
    $timestamp_to: String!
    $appointment_status: [String]!
    $booking_type: [String]!
    $filter_by_role: String
    $BranchRouteId: [String]
    $BusUserId: [String]
    $BranchVehicleId: [String]
  ) {
    getBranchVehiclesRoutesViews(
      GetBranchVehiclesRoutesViewsInput: {
        timestamp_from: $timestamp_from
        timestamp_to: $timestamp_to
        appointment_status: $appointment_status
        booking_type: $booking_type
        filter_by_role: $filter_by_role
        BranchRouteId: $BranchRouteId
        BusUserId: $BusUserId
        BranchVehicleId: $BranchVehicleId
      }
    ) {
      singleDayVehiclesRoutesViews {
        date
        views {
          branchVehicleView {
            name
            color
            type
            status
            plate_number
            image
            daily_capacity
          }
          branchVehicleRoutesViews {
            petsViews {
              id
              name
              profile_pic
              address
              sortIndex
              routeTags
            }
            branchRouteView {
              id
              name
              color
              start_time
            }
            busUsersViews {
              name
              profile_pic
            }
            capacity_usage
          }
        }
      }
    }
  }
`;

export const AddBranchChatRoom = gql`
  ${CHATROOM_FRAGMENT}
  mutation addBranchChatRoom($BusUserId: [String], $is_bus_room: Boolean!, $AppUserId: [String]) {
    addBranchChatRoom(AddBranchChatRoomInput: { BusUserId: $BusUserId, is_bus_room: $is_bus_room, AppUserId: $AppUserId }) {
      ...ChatRoomFragment
    }
  }
`;
